import React, {useState, useEffect, useRef} from 'react';
import axios from "axios";
import ItemForm from './itemFormHolder.jsx';
import { Link } from 'react-router-dom';
import Routing from '../../utils/apiRouting.jsx';
import { motion } from 'framer-motion';
import scrollvariants from '../../utils/scrollvariants.jsx';
import Toast from '../../toast/index.jsx';
import formValidator from '../../utils/formValidator.js';
import { Helmet } from 'react-helmet-async';

export default function Furniture() {
    const routing = Routing();
    const ref = useRef(null);
    const executeScroll = () => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 

    const [showNew, setShowNew] = useState(true);
    const [edit, setEdit] = useState(false);
    const [items, setItems] = useState({
        service: 'furniture moving',
        item: '',
        unit: 'cm',
        length: 0,
        width: 0,
        height: 0,
        assembly: 'no',
        quantity: '1',
        remarks: ''
    });
    const [allItems, setAllItems] = useState([]);

    const [toastState, setToastState] = useState('hidden');
    const [toastText, setToastText] = useState('');

    function handleToast(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
        setTimeout(() => {
            setToastState('hidden');
            setToastText('')
        }, 3000)
    }

    useEffect(() => {
         axios.get(`${routing}/api/v1/session`, {
            withCredentials: true
        })
        .then((res) => {
            if (res.data.data.length > 0) {
                setShowNew(false);
                setAllItems(res.data.data);
            } else {
                setShowNew(true);
            }
        })
        .catch(err => {
            handleToast('error', 'An error has occurred');
        })
    }, [])

    function handleChange(event) {
        const {name, value} = event.target
        if (name === 'length' || name === 'height' || name === 'width') {
            if (value > 0) {
                setItems({...items, [name]: value})
            }
        } else {
            setItems({...items, [name]: value})
        }
    }

    function handleChangeMulti(event, ind) {
        const {name, value} = event.target;

        let inputs = allItems.slice();
        if (name === 'length' || name === 'height' || name === 'width') {
            if (value > 0) {
                for(let i in inputs){
                    if(Number(i) === Number(ind)){
                        inputs[i][name] = value;
                        setAllItems(inputs);
                        break;
                    }
                }
            }
        } else {
            for(let i in inputs){
                if(Number(i) === Number(ind)){
                    inputs[i][name] = value;
                    setAllItems(inputs);
                    break;
                }
            }
        }
    }

    function addToCart(event) {
        event.preventDefault();
        let newItems;
        let valid = formValidator.itemValidator([items]);
        
        if (valid) {
            newItems = [...allItems, items];
            
            axios.post(`${routing}/api/v1/session`, newItems, {
                withCredentials: true
            })
            .then((res) => {
                setAllItems(res.data.data);
                handleToast('success', 'Added to Cart!');
                setItems({
                    service: 'furniture moving',
                    item: '',
                    unit: 'cm',
                    length: '',
                    width: '',
                    height: '',
                    assembly: 'no',
                    quantity: 1,
                    remarks: ''
                })
            })
            .catch((error) => {
                console.log(error)
                handleToast('error', 'An error has occurred');
            })
        } else {
            showNew && handleToast('error', 'Please ensure form is filled');
        }
        setShowNew(true);
        executeScroll();
        
    }

    function handleEdit(event, ind) {
        event.preventDefault();
        let valid = formValidator.itemValidator(allItems);
        if (valid) {
            axios.post(`${routing}/api/v1/session`, allItems, {
                withCredentials: true
            })
            .then((res) => {
                setAllItems(res.data.data);
                setEdit(false);
                handleToast('success', 'Item has been updated!');
                setItems({
                    service: 'furniture moving',
                    item: '',
                    unit: 'cm',
                    length: '',
                    width: '',
                    height: '',
                    assembly: 'no',
                    quantity: 1,
                    remarks: ''
                })
            })
            .catch((error) => {
                handleToast('error', 'An error has occurred');
            })
        } else {
            handleToast('error', 'Please ensure form is filled');
        }

    }

    function handleDelete(event, ind) {
        event.preventDefault();
        let deleteItems = allItems.filter((i, index) => index === Number(ind))
        axios.delete(`${routing}/api/v1/session`, {"data": deleteItems[0], withCredentials:true})
        .then((res) => {
            if (res.data.type === "success") {
                if (res.data.data.length === 0) {
                    setShowNew(true);
                } else {
                    setShowNew(false);
                }
                setEdit(false);
                setAllItems(res.data.data);
                handleToast('success', 'Item has been deleted!');
                setItems({
                    service: 'furniture moving',
                    item: '',
                    unit: 'cm',
                    length: '',
                    width: '',
                    height: '',
                    assembly: 'no',
                    quantity: '1',
                    remarks: ''
                })
            } 
        })
        .catch(error => {
            handleToast('error', 'An error has occurred');
        })
    }

    return (
        <div>
            <Helmet>
                <title>Aegis Logistic Solutions - Furniture Move Add Items</title>
                <meta name="description" content="Welcome to Aegis Logistic Solutions. We seek to be Singapore's no.1 Mover & Logistic Solutions Provider"/>
                <link rel="canonical" href="https://aegislogistic.solutions/services/furniture"/>
            </Helmet>
            <Toast toastState={toastState} setToastState={setToastState} toastText={toastText}/>
            <br/>
            <div className='padding-container'>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1>Load The Lorry</h1>
                        <br/>
                        <br/>
                        <div style={{textAlign:'center'}}>
                            <img src={"/bed.png"} style={{ borderRadius:'2rem'}} className='img-medium' alt="about-us"/>
                        </div>
                        <br/>
                        <br/>
                        <div>
                            <p>Send us your details, and we will get back to via WhatsApp for further confirmation! Thank you for engaging Aegis Logistic Solutions, your support means the world to us &#10084;&#65039;</p>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <motion.div initial="offscreen" whileInView="onscreen" variants={scrollvariants.scrollvariantsbottom} viewport={{ once: true }}>
                    {
                        allItems.map((currItem, ind) => {
                            return (
                                <div key={ind} className='grid-section grid-section-color-1 section-margin-bottom'>
                                    <div className='grid-section-overlay'>
                                        <div className='section-form-div'>
                                        <ItemForm type='current' edit={edit} setEdit={setEdit} handleEdit={handleEdit} handleChange={handleChange} handleDelete={handleDelete} addToCart={addToCart} items={items} ind={ind} currItem={currItem} handleChangeMulti={handleChangeMulti} allItems={allItems}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div ref={ref}>
                        {
                            showNew && 
                            <div className='grid-section grid-section-color-1 section-margin-bottom'>
                                <div className='grid-section-overlay'>
                                    <div className='section-form-div'>
                                    <ItemForm type='new' edit={edit} setEdit={setEdit} handleEdit={handleEdit} handleChange={handleChange} handleDelete={handleDelete} addToCart={addToCart} items={items}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div>
                        <button className='transparent-btn' onClick={addToCart}>Save & Add Item</button>
                        <br/>
                        <br/>
                        <Link to={allItems.length > 0 && '/services/furniture/checkout'} ><button className={allItems.length === 0 ? 'transparent-btn-disabled' : 'yellow-btn'}>Checkout</button></Link>
                    </div>
                </motion.div>

            </div>
        </div>
    )
}