import { motion, AnimatePresence } from "framer-motion"
import { Link } from 'react-router-dom';
import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import * as FiIcons from 'react-icons/fi';
import * as BsIcons from 'react-icons/bs';

const NavMenu = ({isVisible, onCycle}) => {
    return (
        <AnimatePresence>
            {isVisible && (
                <motion.div className='navbar-menu' key="child" initial={{ opacity: 0, top:0 }} animate={{ opacity: 1, top:100  }} exit={{ opacity: 0, top:-1000 }}>
                    <div className="navbar-menu-header">
                        <Link to="/"><p className="aegis-font-color" style={{cursor:"pointer"}} onClick={onCycle}><AiIcons.AiFillHome></AiIcons.AiFillHome> Home</p></Link>
                        <br/><br/>
                        <a className='sidebarLink' rel="noreferrer" href="https://search.google.com/local/reviews?placeid=ChIJP4xml1s92jERWpKPCF0Vm2M" target="_blank"><img layout='fixed' width={32} height={32} src="/google.png" alt="google reviews"/></a>
                        <a className='sidebarLink' rel="noreferrer" href="https://www.facebook.com/AegisLogisticSolutions/reviews/" target="_blank"><img layout='fixed' width={32} height={32} src="/facebook.png" alt="facebook"/></a>
                        <a className='sidebarLink' rel="noreferrer" href="https://www.carousell.sg/aegislogisticsolutions/reviews/" target="_blank"><img layout='fixed' width={32} height={32} src="/carou.png"  alt="carousell"/></a>
                        <a className='sidebarLink' rel="noreferrer" href="https://www.instagram.com/aegislogisticsolutions/?hl=en" target="_blank"><BsIcons.BsInstagram style={{'fontSize':'2rem'}}/></a>
                    </div>
                    <br/>
                    <br/>
                    <div className='grid-2-container'>
                        <div className='grid-nav-section'>
                            <div className='grid-nav-wrapper'>
                                <h2 className="aegis-font-white">Aegis Services Info</h2><br/>
                                <a href='http://api.whatsapp.com/send?phone=6581012340' style={{textDecoration:"none"}} target="_blank" rel="noreferrer"><p className="aegis-font-color" style={{cursor:"pointer"}} onClick={onCycle}><AiIcons.AiOutlineExclamation></AiIcons.AiOutlineExclamation> Urgent Moving</p></a><br/>
                                <Link to="/services/furniture"><p className="aegis-font-color" style={{cursor:"pointer"}} onClick={onCycle}><AiIcons.AiOutlineCodeSandbox></AiIcons.AiOutlineCodeSandbox> Furniture Moving</p></Link><br/>
                                <Link to="/services/residential"><p className="aegis-font-color" style={{cursor:"pointer"}} onClick={onCycle}><BiIcons.BiBuildingHouse></BiIcons.BiBuildingHouse> Residential Relocation</p></Link><br/>
                                <Link to="/services/commercial"><p className="aegis-font-color" style={{cursor:"pointer"}} onClick={onCycle}><BiIcons.BiBuilding></BiIcons.BiBuilding> Commercial Relocation</p></Link><br/>
                                <Link to="/services/manpower"><p className="aegis-font-color" style={{cursor:"pointer"}} onClick={onCycle}><BiIcons.BiBuilding></BiIcons.BiBuilding> Manpower Services</p></Link><br/>
                                <Link to="/services/storagedisposal"><p className="aegis-font-color" style={{cursor:"pointer"}} onClick={onCycle}><BiIcons.BiBuilding></BiIcons.BiBuilding> Storage & Disposal</p></Link><br/>
                            </div>
                        </div>
                        <div className='grid-nav-section'>
                            <div className='grid-nav-wrapper'>
                                <h2 className="aegis-font-white">Know Aegis</h2><br/>
                                <Link to="/aegis/aboutus"><p className="aegis-font-color" style={{cursor:"pointer"}} onClick={onCycle}><FiIcons.FiTruck></FiIcons.FiTruck> About Us</p></Link><br/>
                                <Link to="/aegis/contactus"><p className="aegis-font-color" style={{cursor:"pointer"}} onClick={onCycle}><BiIcons.BiPhoneCall></BiIcons.BiPhoneCall> Contact us</p></Link><br/>
                                <Link to="/aegis/partnerus"><p className="aegis-font-color" style={{cursor:"pointer"}} onClick={onCycle}><AiIcons.AiOutlineTeam></AiIcons.AiOutlineTeam> Partner Us - Business</p></Link><br/>
                                <Link to="/aegis/joinus"><p className="aegis-font-color" style={{cursor:"pointer"}} onClick={onCycle}><BiIcons.BiBriefcase></BiIcons.BiBriefcase> Join Us</p></Link><br/>
                            </div>
                        </div>
                    </div>
                </motion.div>
            )}

        </AnimatePresence>
    )
}

export default NavMenu;