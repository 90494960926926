import React from 'react';
import scrollVariants from '../utils/scrollvariants';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

export default function Aboutus() {
    return (
        <div>
            <Helmet>
                <title>Aegis Logistic Solutions - About Us</title>
                <meta name="description" content="Welcome to Aegis Logistic Solutions. We seek to be Singapore's no.1 Mover & Logistic Solutions Provider"/>
                <link rel="canonical" href="https://aegislogistic.solutions/aegis/aboutus"/>
            </Helmet>
            <div className='padding-container'>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1 className='aegis-font-color-2'>About Us</h1>
                        <p>We don't write fancy but with heart</p>
                        <br/>
                        <div style={{textAlign:'center'}}>
                            <img src={"/aboutus.png"} style={{ borderRadius:'2rem'}} className='img-medium' alt="about-us"/>
                        </div>
                    </div>
                </div>
            </div>
            <motion.div className='padding-container div-bg-color-2' initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <div className='grid-section div-bg-color-3'>
                    <div className='grid-section-overlay'>
                        <h1 className='aegis-font-color-2'>#StoryTime</h1>
                        <p>As a team of young movers consisting of family and friends, we treat all our customers as part of our family. Starting out in 2015, relying on the arms of 2 young chaps and a van, we have come a long way to be who we are today and none of this will be possible without our greatest supporters, YOU. We provide quality house moving & item delivery services of any sort with our team of specialists. With years of experiences and skills, you can entrust us to handle all your logistic activities. We're a team of Young, Friendly & Passionate Movers with plenty of experiences. We also bend over backwards to ensure your goods goes undamaged or your money returned! As the world embraces technology, moving forward, we aim to be Singapore's top platform moving company.</p>
                    </div>
                </div>
            </motion.div>
            <motion.div className='padding-container div-bg-color-1' initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1 className='aegis-font-color-2'>#MeetTheTeam</h1>
                        <br/>
                        <p>Insert Photo</p>
                        <h2>Martin Lim</h2>
                        <p>Insert Caption</p>
                        <br/>
                        <p>Insert Photo</p>
                        <h2>Kazushi Fujiwara</h2>
                        <p>Insert Caption</p>
                    </div>
                </div>
            </motion.div>
        </div>

    )
}