const scrollvariantstop = {
    offscreen: {
        y: -300
    },
    onscreen: {
        y: 0,
        transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
        }
    }
    };

const scrollvariantsright = {
    offscreen: {
        x: 300
    },
    onscreen: {
        x: 0,
        transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
        }
    }
};

const scrollvariantsbottom = {
    offscreen: {
        y: 300
    },
    onscreen: {
        y: 0,
        transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
        }
    }
};

let scrollvariants = {
    scrollvariantstop: scrollvariantstop,
    scrollvariantsright: scrollvariantsright,
    scrollvariantsbottom: scrollvariantsbottom
}
export default scrollvariants;