import React from 'react';
import { FaqData } from "./FaqData";
import * as FaIcons from 'react-icons/fa';

const Faq = () => {
    function showBody(n) {
        const bodyClass = document.getElementById(n.n).className
        bodyClass === 'faq-bodyNone' ? 
        document.getElementById(n.n).className = "faq-body" 
        : 
        document.getElementById(n.n).className = "faq-bodyNone"
    }

    return (
        <div className='padding-container grid-section-color-4'>
            {/* <div className='grid-section grid-section-color-1'> */}
                <div className='grid-section-overlay'>
                    <h1 className="aegis-font-color-3">#FAQ</h1>
                    <h3 className="aegis-font-color-3">Aegis Logistic Solutions</h3>
                    <br/>
                    {
                        FaqData.map((item, index) => {
                            const n = item.n
                            return (
                                <div className="faq-wrapper" key={item.n}>
                                    <div className="faq-header" onClick={() => showBody({n})}>
                                        <div className="faq-headword">
                                            <h4>{item.head}</h4>
                                        </div>
                                        <div className="faq-icon">
                                            <FaIcons.FaPlus/>
                                        </div>
                                    </div>
                                    <div className="faq-bodyNone" id={n}>
                                        <p className='aegis-font-color'>{item.body}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            {/* </div> */}
        </div>
    )
}

export default Faq;