import React from 'react';
import * as FaIcons from 'react-icons/fa';
import scrollVariants from '../utils/scrollvariants';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

export default function ContactUs() {

    return (
        <div>
            <Helmet>
                <title>Aegis Logistic Solutions - Contact Us</title>
                <meta name="description" content="Welcome to Aegis Logistic Solutions. We seek to be Singapore's no.1 Mover & Logistic Solutions Provider"/>
                <link rel="canonical" href="https://aegislogistic.solutions/aegis/contactus"/>
            </Helmet>
            <div className='padding-container'>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1 className='aegis-font-color-2'>Contact Us</h1>
                        <div style={{textAlign:'center'}}>
                            <img src={"/map.png"} className='img-medium' alt="map"/>
                        </div>
                    </div>
                </div>
            </div>
            <motion.div className='padding-container div-bg-color-2' initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <div className='grid-section div-bg-color-3'>
                    <div className='grid-section-overlay'>
                        <h1 className='aegis-font-color'>Aegis Logistic Solutions</h1>
                        <p>Your next-door Movers~</p>
                        <br/>
                        <h1 className='aegis-font-color'>Operating Hours</h1>
                        <p>Monday to Saturday: 9am - 9pm</p>
                        <br/>
                        <h1 className='aegis-font-color'>Sales & Partnership</h1>
                        <br/>
                        <p>We seek to portray our moving experiences</p>
                        <br/>
                        <strong>Martin: </strong><a href="tel:81012340" style={{marginRight:'0.5rem'}}><FaIcons.FaPhoneAlt/></a><a href="http://api.whatsapp.com/send?phone=6581012340" target="_blank" rel="noreferrer"><FaIcons.FaWhatsapp/></a><br/>
                        <strong>Email: </strong><a href="mailto:aegislogisticsolutions@gmail.com" style={{color:"#184B4C",fontSize:"1rem",textDecoration:"none"}}> <span className="aegis-font-color" style={{wordBreak:"break-all"}}>aegislogisticsolutions</span>@gmail.com</a><br/><br/>
                        <h1 className='aegis-font-color'>Feedback on site?</h1>
                        <br/>
                        <p>We aim to ease your moving experiences</p>
                        <br/>
                        <strong>Kazushi: </strong><a href="tel:96511366" style={{marginRight:'0.5rem'}}><FaIcons.FaPhoneAlt/></a><a href="http://api.whatsapp.com/send?phone=6596511366" target="_blank" rel="noreferrer"><FaIcons.FaWhatsapp/></a><br/>
                    </div>
                </div>
            </motion.div>
        </div>

    )
}