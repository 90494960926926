import React, {useState} from 'react';
import scrollVariants from '../utils/scrollvariants';
import { motion } from 'framer-motion';
import axios from "axios";
import Routing from '../utils/apiRouting';
import Toast from '../toast';
import formValidator from '../utils/formValidator';
import { Helmet } from 'react-helmet-async';

export default function JoinUs() {
    const routing = Routing();
    const [query, setQuery] = useState({
        type: 'joinus',
        name:'',
        email:'',
        contact:'',
        message:''
    });

    const [toastState, setToastState] = useState('hidden');
    const [toastText, setToastText] = useState('');

    function handleToast(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
        setTimeout(() => {
            setToastState('hidden');
            setToastText('')
        }, 3000)
    }

    function handleToastSuccess(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
    }
    
    function handleChange(event) {
        const {name, value} = event.target
        setQuery({...query, [name]: value})
    }

    function enquire(event) {
        event.preventDefault();
        let valid = formValidator.queryValidator(query);
        if (valid) {
            axios.post(`${routing}/api/v1/query`, query, {
                withCredentials: true
            })
            .then((res) => {
                handleToastSuccess('success', 'Query has been received!');
                setQuery({
                    type: 'joinus',
                    name:'',
                    email:'',
                    contact:'',
                    message:''
                })
            })
            .catch((error) => {
                handleToast('error', 'An error has occurred');
            })
        } else {
            handleToast('error', 'Please ensure form is filled');
        }
    }
    return (
        <div>
            <Helmet>
                <title>Aegis Logistic Solutions - Join Us</title>
                <meta name="description" content="Welcome to Aegis Logistic Solutions. We seek to be Singapore's no.1 Mover & Logistic Solutions Provider"/>
                <link rel="canonical" href="https://aegislogistic.solutions/aegis/joinus"/>
            </Helmet>
            <Toast toastState={toastState} setToastState={setToastState} toastText={toastText}/>
            <div className='padding-container'>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1 className='aegis-font-color-2'>Join Us</h1>
                        <br/>
                        <p>Looking for flexible schedule? Be your own boss? HUAT AH, you came to the right place!</p>
                        <br/>
                        <div style={{textAlign:'center', borderRadius:'2rem'}}>
                            <img src={"/truck.png"} style={{ borderRadius:'2rem'}} className='img-medium' alt="how-it-works"/>
                        </div>
                    </div>
                </div>
            </div>
            <motion.div className='padding-container div-bg-color-2' initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <div className='grid-section div-bg-color-3'>
                    <div className='grid-section-overlay'>
                        <h1 className='aegis-font-color-2'>#HUAT</h1>
                        <p>Calling all Movers~</p>
                        <br/>
                        <br/>
                        <div className='grid-2-container'>
                            <div className='card-grid-section grid-section-color-2'>
                                <div className='card-grid-section-2'>
                                    <div style={{padding:'2rem'}}>
                                        <br/>
                                        <h1>Be Your Own Boss</h1>
                                        <br/><h1 style={{'fontSize':'100px'}}>&#128074;</h1><br/>
                                    </div>

                                    <div className='section-footer grid-section-color-4' style={{borderBottomLeftRadius:'2rem', borderBottomRightRadius:'2rem',padding:'2rem'}}>
                                        <p className='aegis-font-white'>All <span className="aegis-font-color">independant movers</span>, be it a team or individual, own a vehicle or none. All we ask
                                        of you is to have a mindset, a mindset to succeed and provide for your loved ones. Sign up and we
                                        will provide you everything and anything you need, we believe in sharing.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card-grid-section grid-section-color-2'>
                                <div className='card-grid-section-2'>
                                    <div style={{padding:'2rem'}}>
                                        <br/>
                                        <h1>Leads Generated Platform</h1>
                                        <br/><h1 style={{'fontSize':'100px'}}>&#127907;</h1><br/>
                                    </div>

                                    <div className='grid-section-color-4' style={{borderBottomLeftRadius:'2rem', borderBottomRightRadius:'2rem',padding:'2rem'}}>
                                        <p className='aegis-font-white'>What&apos;s the <span className="aegis-font-color">catch</span>? Onboarding the Aegis Family 
                                        enables you to focus on moving and watching your bank account grow.</p>
                                        <br/>
                                        <ul style={{listStyleType:'none'}}>
                                            <li className='aegis-font-white'>We handle <span className="aegis-font-color">Sales</span> for you</li>
                                            <li className='aegis-font-white'>We handle <span className="aegis-font-color">Jobs</span> for you</li>
                                            <li className='aegis-font-white'>We handle <span className="aegis-font-color">Lorry</span> for you</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className=' card-grid-section grid-section-color-2'>
                                <div className='card-grid-section-2'>
                                    <div style={{padding:'2rem'}}>
                                        <br/>
                                        <h1>Sufficient Jobs Assured</h1>
                                        <br/><h1 style={{'fontSize':'100px'}}>&#128181;</h1><br/>
                                    </div>

                                    <div className='grid-section-color-4' style={{borderBottomLeftRadius:'2rem', borderBottomRightRadius:'2rem',padding:'2rem'}}>
                                        <p className='aegis-font-white'>We analyse our sales volume before onboarding any independant movers. The beauty
                                        of such act translate to <span className="aegis-font-color">guaranteed</span> gross income of
                                        $7000 per team. Think fast and act fast.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card-grid-section grid-section-color-2'>
                                <div className='card-grid-section-2'>
                                    <div style={{padding:'2rem'}}>
                                        <br/>
                                        <h1>Stll Waiting?</h1>
                                        <br/><h1 style={{'fontSize':'100px'}}>&#8987;</h1><br/>
                                    </div>

                                    <div className='grid-section-color-4' style={{borderBottomLeftRadius:'2rem', borderBottomRightRadius:'2rem',padding:'2rem'}}>
                                        <p className='aegis-font-white'>2 <span className="aegis-font-color">VACANCIES</span> LEFT! No upfront payment, no obligations, just relax and talk to us, we won't bite!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
            <motion.div className='padding-container' initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1 className='aegis-font-color-2'>Reach Us!</h1>
                        <br/>
                        <p>Drop us an enquiry and we will get in touch with you~</p>
                        <br/>
                        <br/>
                        <div>
                            <label htmlFor="name" className="aegis-font-color-1">How do we address you?</label><span className="error-message">{query.name === '' ? <span> (Required)</span> : null}</span>
                            <input type="text" name="name" value={query.name} onChange={handleChange}/>

                            <label htmlFor="contact" className="aegis-font-color-1">What's your number?</label><span className="error-message">{query.contact === '' ? <span> (Required)</span> : null}</span>
                            <input type="text" name="contact" value={query.contact} onChange={handleChange}/>

                            <label htmlFor="email" className="aegis-font-color-1">What's your email?</label><span className="error-message">{query.email === '' ? <span> (Required)</span> : null}</span>
                            <input type="text" name="email" value={query.email} onChange={handleChange}/>

                            <label htmlFor="message" className="aegis-font-color-1">Leave us a message!</label><span className="error-message">{query.message === '' ? <span> (Required)</span> : null}</span>
                            <textarea name="message" placeholder='Hi, I am a 3-man team with 5 years of moving experience....' value={query.message} onChange={handleChange}/>

                            <button className='transparent-btn' onClick={enquire}>Enquire Now</button>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}