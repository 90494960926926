import React from 'react';
import ItemFormBlockExisting from './itemFormExisiting';
import ItermFormBlockNew from './itemFormNew';

export default function ItemFormHolder({type, edit, setEdit, handleEdit, handleChange, handleDelete, addToCart, items, ind, currItem, handleChangeMulti, allItems}) {
    return (
        <>
            {
                type === 'current' &&
                <ItemFormBlockExisting type={type} edit={edit} setEdit={setEdit} handleEdit={handleEdit} handleChange={handleChange} handleDelete={handleDelete} addToCart={addToCart} ind={ind} items={items} currItem={currItem} handleChangeMulti={handleChangeMulti} allItems={allItems}/>

            }
            {
                type === 'new' && 
                <ItermFormBlockNew type={type} handleChange={handleChange} handleDelete={handleDelete} addToCart={addToCart} ind={ind} items={items} currItem={currItem}/>
            }
        </>

    )
}