import React from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import Howitworks from './howitworks/howitworks';
import Landing from './landing';
import Reviews from './reviews/reviews'
import Featured from './featured/featured';
import Gallery from './gallery/gallery';
import Faq from './faq/faq';
import scrollVariants from '../utils/scrollvariants';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Aegis Logistic Solutions - Furniture Movers & Logistics</title>
                <meta name="description" content="Welcome to Aegis Logistic Solutions. We seek to be Singapore's no.1 Mover & Logistic Solutions Provider"/>
                <link rel="canonical" href="https://aegislogistic.solutions"/>
            </Helmet>
            <Landing/>
            <motion.div initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <Howitworks/>
            </motion.div>
            <motion.div initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <Featured/>
            </motion.div>
            <motion.div initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <Reviews/>
            </motion.div>
            <motion.div initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <Gallery/>
            </motion.div>
            <motion.div initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <Faq/>
            </motion.div>
        </>
    )
}

export default Home;