import React, {useState} from 'react';
import ResidentialForm from './residentialForm';
import axios from "axios";
import Routing from '../../utils/apiRouting';
import Toast from '../../toast';
import formValidator from '../../utils/formValidator';
import { Helmet } from 'react-helmet-async';

export default function Residential() {
    const routing = Routing();

    const [residential, setResidential] = useState({
        currPropertyType: 'HDB',
        numRooms: 5,
        floor: 1,
        stairs: 0,
        name: '',
        mobile: '',
        item: '',
        movingDate: '',
        boxes: 0,
        boxesDeliver: '',
        address: '',
        info: '',
        lift: 'yes',
        type: 'RR',
        dropoffAddress: '',
        dropoffInfo: '',
        dropoffLift: 'yes',
    })

    const [toastState, setToastState] = useState('hidden');
    const [toastText, setToastText] = useState('');

    function handleToast(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
        setTimeout(() => {
            setToastState('hidden');
            setToastText('')
        }, 3000)
    }

    function handleToastSuccess(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
    }
    
    function handleChange(event) {
        const {name, value} = event.target
        setResidential({...residential, [name]: value})
    }

    function getaQuote() {
        let valid = formValidator.residentialValidator(residential);
        if (valid) {
            axios.post(`${routing}/api/v1/jobdetails/housemoving`, residential, {
                withCredentials: true
            })
            .then((res) => {
                console.log(res);
                handleToastSuccess('success', 'Query has been received! We are currently in beta mode and will incorporate WhatsApp confirmation soon!');
                setResidential({
                    currPropertyType: 'HDB',
                    numRooms: 5,
                    floor: 1,
                    stairs: 0,
                    name: '',
                    mobile: '',
                    item: '',
                    movingDate: '',
                    boxes: 5,
                    boxesDeliver: '',
                    address: '',
                    info: '',
                    lift: 'yes',
                    type: 'RR',
                    dropoffAddress: '',
                    dropoffInfo: '',
                    dropoffLift: 'yes',
                })
            })
            .catch(err => {
                handleToast('error', 'An error has occurred');
            })
        } else {
            handleToast('error', 'Please ensure form is filled');
        }
    }

    return (
        <div>
                <Helmet>
                <title>Aegis Logistic Solutions - Residential Relocation</title>
                <meta name="description" content="Welcome to Aegis Logistic Solutions. We seek to be Singapore's no.1 Mover & Logistic Solutions Provider"/>
                <link rel="canonical" href="https://aegislogistic.solutions/services/residential"/>
            </Helmet>
            <Toast toastState={toastState} setToastState={setToastState} toastText={toastText}/>
            <br/>
            <div className='padding-container'>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1>Residential Relocation</h1>
                        <br/>
                        <br/>
                        <div style={{textAlign:'center'}}>
                            <img src={"/livingroom.png"} style={{ borderRadius:'2rem'}} className='img-medium' alt="about-us"/>
                        </div>
                        <br/>
                        <br/>
                        <div>
                            <p>Send us your details, and we will get back to via WhatsApp for further confirmation! Thank you for engaging Aegis Logistic Solutions, your support means the world to us &#10084;&#65039;</p>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <ResidentialForm residential={residential} handleChange={handleChange}/>
                <button className='transparent-btn' onClick={getaQuote}>Get a Quote</button>

            </div>
        </div>
    )
}