import React from 'react';
import { motion } from 'framer-motion';
import scrollvariants from '../../../utils/scrollvariants';

export default function CheckoutForm({pickUp, setPickUp, handlePickUpChange}) {
    
    return (
        <motion.form initial="offscreen" whileInView="onscreen" variants={scrollvariants.scrollvariantsbottom} viewport={{ once: true }}>
            <h1 className="aegis-font-color-2">Let us know more about you...</h1>
            <br/>
            <br/>
            <label htmlFor="movingDate" className="aegis-font-color-1">Moving Date</label><span className="error-message">{pickUp.movingDate === '' ? <span> (Required)</span> : null}</span>
            <span className="error-message">{Date.parse(pickUp.movingDate) < new Date().setDate(new Date().getDate() + 1) ? <span> (For urgent move, reach us via WhatsApp)</span> : null}</span>
            <input type="datetime-local" name="movingDate" value={pickUp.movingDate} onChange={handlePickUpChange}/>
            <br/>
            <label htmlFor="name" className="aegis-font-color-1">How do we address you?</label><span className="error-message">{pickUp.name === '' ? <span> (Required)</span> : null}</span>
            <input type="text" name="name" value={pickUp.name} onChange={handlePickUpChange}/>
            <br/>
            <label htmlFor="mobile" className="aegis-font-color-1">How do we contact(mobile) you?</label><span className="error-message">{pickUp.mobile === '' ? <span> (Required)</span> : null}</span>
            <input type="text" name="mobile" value={pickUp.mobile} onChange={handlePickUpChange}/>
            <br/>
            <label htmlFor="address" className="aegis-font-color-1">Where do we pick up your items?</label><span className="error-message">{pickUp.address === '' ? <span> (Required)</span> : null}</span>
            <input type="text" name="address" value={pickUp.address} onChange={handlePickUpChange}/>
            <br/>
            <label htmlFor="pickUpLift" className="aegis-word">Pick up location accessible by lift?</label><span className="error-message">{pickUp.pickUpLift === '' ? <span> (Required)</span> : null}</span>
            <br/>
            <br/>
            <label style={{paddingRight:'1rem'}}>
                <input type="radio" name="pickUpLift" value='no' onChange={handlePickUpChange} checked={pickUp.pickUpLift==='no'}/>
                No
            </label>
            <label>
                <input type="radio" name="pickUpLift" value='yes' onChange={handlePickUpChange} checked={pickUp.pickUpLift==='yes'}/>
                Yes
            </label>
        </motion.form>
    )
}