import React from 'react';
import scrollvariants from '../../utils/scrollvariants';
import { motion } from 'framer-motion';

export default function StorageDisposalForm({storageDisposal, handleChange}) {
    return (
        <motion.div initial="offscreen" whileInView="onscreen" variants={scrollvariants.scrollvariantsbottom} viewport={{ once: true }}>
            <div className='grid-section grid-section-color-1 section-margin-bottom'>
                <div className='grid-section-overlay'>
                    <div className='section-form-div'>
                        <h2 className="aegis-font-color-2 input-inline">What service do you need?</h2>
                        <span className="error-message">{storageDisposal.service === '' ? <span> (Required)</span> : null}</span>
                        <select name="service" value={storageDisposal.service} onChange={handleChange}>
                            <option value='Storage'>Storage</option>
                            <option value='Disposal'>Disposal</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='grid-section grid-section-color-1 section-margin-bottom'>
                <div className='grid-section-overlay'>
                    <div className='section-form-div'>
                        <h2 className="aegis-font-color-2 input-inline">{storageDisposal.service}</h2>
                        <br/>
                        <br/>
                        <label htmlFor="name" className="aegis-font-color-1">How do we address you?</label><span className="error-message">{storageDisposal.name === '' ? <span> (Required)</span> : null}</span>
                        <input type="text" name="name" value={storageDisposal.name} onChange={handleChange}/>
                        <br/>
                        <label htmlFor="mobile" className="aegis-font-color-1">How do we contact(mobile) you?</label><span className="error-message">{storageDisposal.mobile === '' ? <span> (Required)</span> : null}</span>
                        <input type="text" name="mobile" value={storageDisposal.mobile} onChange={handleChange}/>
                        <br/>
                        <label htmlFor="items" className="aegis-font-color-1">Items for {storageDisposal.service}?</label><span className="error-message">{storageDisposal.items === '' ? <span> (Required)</span> : null}</span>
                        <textarea name="items" value={storageDisposal.items} onChange={handleChange}/>
                        <br/>
                        <label htmlFor="serviceDate" className="aegis-font-color-1">Preferred Pick Up Date</label>
                        <span className="error-message">{storageDisposal.serviceDate === '' ? <span> (Required)</span> : null}</span>
                        <span className="error-message">{Date.parse(storageDisposal.serviceDate) < new Date().setDate(new Date().getDate() + 1) ? <span> (For urgent pick up, reach us via WhatsApp)</span> : null}</span>
                        <input type="datetime-local" name="serviceDate" value={storageDisposal.serviceDate} onChange={handleChange}/>
                        <br/>
                        <label htmlFor="address" className="aegis-font-color-1">Where do we pick up your items?</label><span className="error-message">{storageDisposal.address === '' ? <span> (Required)</span> : null}</span>
                        <input type="text" name="address" value={storageDisposal.address} onChange={handleChange}/>
                        <br/>
                        <label htmlFor="lift" className="aegis-font-color-1">Pick Up Location Lift Accessible?</label><span className="error-message">{storageDisposal.lift === '' ? <span> (Required)</span> : null}</span>
                        <br/>
                        <br/>
                        <label style={{paddingRight:'1rem'}}>
                            <input type="radio" name="lift" value='no' onChange={handleChange} checked={storageDisposal.lift==='no'}/>
                            No
                        </label>
                        <label>
                            <input type="radio" name="lift" value='yes' onChange={handleChange} checked={storageDisposal.lift==='yes'}/>
                            Yes
                        </label>
                        <br/>
                        <br/>
                        <label htmlFor="stairs" className="aegis-font-color-1">Pick Up Location Has Stairs?</label><span className="error-message">{storageDisposal.stairs === '' ? <span> (Required)</span> : null}</span>
                        <select name="stairs" value={storageDisposal.stairs} onChange={handleChange}>
                            {
                                [...Array(31).keys()].filter(x => x >= 0).map((n, i) => {
                                    return(
                                        <option key={i} value={n}>{n}</option>
                                    )
                                })
                            }
                        </select>
                        <br/>
                        {
                            storageDisposal.service === 'Storage' &&
                            <div className="grid-2-container" style={{gridGap: "0rem", textAlign:'left'}}>
                                <div>
                                    <label htmlFor="duration" className="aegis-font-color-1">Duration</label><span className="error-message">{storageDisposal.duration === '' ? <span> (Required)</span> : null}</span>
                                    <select name="duration" value={storageDisposal.duration} onChange={handleChange}>
                                        {
                                            [...Array(100).keys()].filter(x => x >= 0).map((n, i) => {
                                                return(
                                                    <option key={i} value={n}>{n}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="durationType" className="aegis-font-color-1">Duration Type</label><span className="error-message">{storageDisposal.durationType === '' ? <span> (Required)</span> : null}</span>
                                    <select name="durationType" value={storageDisposal.durationType} onChange={handleChange}>
                                        <option value='month'>month</option>
                                        <option value='week'>week</option>
                                    </select>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </motion.div>
    )
}