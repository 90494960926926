import React from 'react';
import { motion } from 'framer-motion';
import * as BsIcons from 'react-icons/bs';
import scrollVariants from '../utils/scrollvariants';
import { Link } from 'react-router-dom';

const Landing = () => {

    return (
        <div className='padding-container'>
        <div className='grid-2-container responsive'>
            <motion.div className='grid-section-main' initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantstop} viewport={{ once: true }}>
                <div className='section-footer'>
                    <h1 className='aegis-font-color'>#Experience</h1>
                    <h1 className='aegis-font-white'>Aegis Logistic Solutions</h1>
                </div>
                <br/>
                <div className='section-header'>
                    <p className='aegis-font-white'>Leave your worries to us. Any logistical issues, we are your solution~</p>
                </div>
                <div className='div-align-right'>
                    <img src={"/lorry.webp"} className='img-small' alt="aegis-lorry"/>
                </div>
                <div className='div-align-left'>
                    <br/>
                    <a href='http://api.whatsapp.com/send?phone=6581012340' target="_blank" rel="noreferrer"><button className='yellow-btn'>Urgent?</button></a>
                </div>
            </motion.div>
            <motion.div className='grid-responsive' initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsright} viewport={{ once: true }}>
                <div className='grid-section-hover grid-section-color-1' >
                    <Link to='/services/furniture' style={{ textDecoration: 'none' }}>
                        <div className='grid-section-overlay-hover'>
                            <div className='section-header'>
                                <div className='section-header-arrow'>
                                    <BsIcons.BsArrowUpRight/>
                                </div>
                                <p>Aegis moves & deliver items of all sizes. Click to start loading the lorry!</p>
                            </div>
                            <div className='section-footer'>
                                <h1 className='aegis-font-color-1'>#Furniture Moving</h1>
                            </div>
                        </div>
                    </Link>
                </div>
                <br/>
                <br/>
                <div className='grid-2-container'>
                    <div className='grid-section-hover grid-section-color-2'>
                        <Link to='/services/residential' style={{ textDecoration: 'none' }}>
                                <div className='grid-section-overlay-hover'>
                                    <div className='section-header'>
                                        <div className='section-header-arrow' style={{color: '#A35709'}}>
                                            <BsIcons.BsArrowUpRight/>
                                        </div>
                                        <p>Aegis moves your house with 10ft/14ft Lorry. Click to start loading the lorry!</p>
                                        <br/>
                                        <p className='error-message'>Half load from $180</p>
                                        <p className='error-message'>Full load from $320</p>
                                    </div>
                                    <div className='section-footer'>
                                        <h1 className='aegis-font-color-2'>#Residential Relocation</h1>
                                    </div>
                                </div>
                        </Link>
                    </div>
                    <div className='grid-section-hover grid-section-color-2'>
                        <Link to='/services/commercial' style={{ textDecoration: 'none' }}>
                                    <div className='grid-section-overlay-hover'>
                                        <div className='section-header'>
                                        <div className='section-header-arrow' style={{color: '#A35709'}}>
                                                <BsIcons.BsArrowUpRight/>
                                            </div>
                                            <p>Professionalism at our core with a team of specialist!</p>
                                        </div>
                                        <div className='section-footer'>
                                            <h1 className='aegis-font-color-2'>#Commercial Relocation</h1>
                                        </div>

                                    </div>
                        </Link>
                    </div>
                </div>
            </motion.div>
        </div>
        <br/>
        <br/>
        <motion.div className='grid-2-container' initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
            <div className='grid-section-hover grid-section-color-3'>
                <Link to='/services/manpower' style={{ textDecoration: 'none' }}>
                        <div className='grid-section-overlay-hover'>
                            <div className='section-header'>
                                <div className='section-header-arrow' style={{color: '#F0E3CA'}}>
                                    <BsIcons.BsArrowUpRight/>
                                </div>
                                <p>Manhandling your home but need extra hands? Aegis's arms are big enough to rely on!</p>
                            </div>
                            <div className='section-footer'>
                                <h1 className='aegis-font-color-3'>#Manpower Service</h1>
                            </div>
                        </div>
                </Link>
            </div>
            <div className='grid-section-hover grid-section-color-3'>
                <Link to='/services/storagedisposal' style={{ textDecoration: 'none' }}>
                            <div className='grid-section-overlay-hover'>
                                <div className='section-header'>
                                    <div className='section-header-arrow' style={{color: '#F0E3CA'}}>
                                        <BsIcons.BsArrowUpRight/>
                                    </div>
                                    <p>Unwanted items or need a temporary storage? We will handle it for you!</p>
                                    <br/>
                                    <p className='aegis-font-color-4'>Starting from $40/mth</p>
                                </div>
                                <div className='section-footer'>
                                    <h1 className='aegis-font-color-3'>#Storage & Disposal</h1>
                                </div>
                            </div>
                </Link>
            </div>
        </motion.div>
    </div>
    )
}

export default Landing;