import {Swiper, SwiperSlide} from "swiper/react"
import "swiper/css";
import "swiper/css/effect-cards";
import React from 'react';
import { EffectCards } from "swiper";

const Gallery = () => {
    function SwiperContainerStyle() {
        return {
          overflow: "hidden",
          paddingBottom: "3rem",
          paddingTop: "3rem"
        }
      }

    
      function SwiperStyle() {
        return {
          height: "fill-content",
          width: "80%"
        }
      }
    
      function SwiperSlideStyle() {
        return {
            texAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "18px",
            color: "none",
            backgroundColor: "none"
        }
      }

      function IMAGE_STYLE() {
        return {
            width: '100%',
            height: 'auto',
            maxHeight: '700px',
            borderRadius: '10px',
            objectFit:'cover',
            objectPosition: 'center',
            layout: 'responsive'
        }
      }

    return (
        <div className='padding-container div-bg-color-3'>

        <div style={SwiperContainerStyle()}>
            <div>
                <h1 className="aegis-font-color-2">#Gallery</h1>
                <h3 className="aegis-font-color-1">Aegis Logistic Solutions</h3>
            </div>
            <br/>
            <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
            style={ SwiperStyle() }
            >
                <SwiperSlide style={ SwiperSlideStyle() }>
                <img layout={'responsive'} src="/gallery1.jpeg"  alt="front lorry" style={ IMAGE_STYLE() }/>
                </SwiperSlide>

                <SwiperSlide style={ SwiperSlideStyle() }>
                <img layout={'responsive'} src="/gallery2.jpeg" alt="side lorry" style={ IMAGE_STYLE() }/>
                </SwiperSlide>

                <SwiperSlide style={ SwiperSlideStyle() }>
                <img layout={'responsive'} src="/gallery3.jpeg" alt="side lorry moving" style={ IMAGE_STYLE() }/>
                </SwiperSlide>

                <SwiperSlide style={ SwiperSlideStyle() }>
                <img layout={'responsive'} src="/gallery4.jpeg" alt="boxes from  lorry" style={ IMAGE_STYLE() }/>
                </SwiperSlide>

                <SwiperSlide style={ SwiperSlideStyle() }>
                <img layout={'responsive'} src="/gallery5.jpeg" alt="assemble bed frame" style={ IMAGE_STYLE() }/>
                </SwiperSlide>

                <SwiperSlide style={ SwiperSlideStyle() }>
                <img layout={'responsive'} src="/gallery6.jpeg" alt="loading lorry" style={ IMAGE_STYLE() }/>
                </SwiperSlide>
            </Swiper>
        </div>
    </div>
    )
}

export default Gallery;