import React from 'react';
import { motion } from 'framer-motion';
import scrollvariants from '../../../utils/scrollvariants';

export default function DropOffForm({dropOff, setDropoff, handleDropOffChange}) {

    return (
        <motion.form initial="offscreen" whileInView="onscreen" variants={scrollvariants.scrollvariantsbottom} viewport={{ once: true }}>
            <h1 className="aegis-font-color-2">Where do we send your items to?</h1>
            <br/>
            <br/>
            <label htmlFor="address" className="aegis-font-color-1">Where do we drop off your items?</label><span className="error-message">{dropOff.address === '' ? <span> (Required)</span> : null}</span>
            <input type="text" name="address" value={dropOff.address} onChange={handleDropOffChange}/>
            <br/>
            <br/>
            <label htmlFor="lift" className="aegis-font-color-1">Drop Off location accessible by lift?</label><span className="error-message">{dropOff.lift === '' ? <span> (Required)</span> : null}</span>
            <br/>
            <br/>
            <label style={{paddingRight:'1rem'}}>
                <input type="radio" name="lift" value='no' onChange={handleDropOffChange} checked={dropOff.lift==='no'}/>
                No
            </label>
            <label>
                <input type="radio" name="lift" value='yes' onChange={handleDropOffChange} checked={dropOff.lift==='yes'}/>
                Yes
            </label>
            <br/>
            <br/>
            <label htmlFor="info" className="aegis-font-color-1">Additional Remarks?</label><span className="notrequired-message"> (Not Required)</span>
            <textarea name="info" value={dropOff.info} onChange={handleDropOffChange}/>
        </motion.form>
    )
}