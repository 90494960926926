import React, {useState, useEffect, useRef} from 'react';
import CheckoutForm from './checkoutForm';
import DropOffForm from './dropoffForm';
import ExtraLocForm from './extraLocForm';
import { Link, useNavigate } from 'react-router-dom';
import * as IoIcons from 'react-icons/io';
import axios from "axios";
import Routing from '../../../utils/apiRouting';
import Toast from '../../../toast';
import formValidator from '../../../utils/formValidator';
import { Helmet } from 'react-helmet-async';


export default function Checkout() {
    const routing = Routing();
    const ref = useRef(null);
    const executeScroll = () => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
    const navigate = useNavigate()
    const [pickUp, setPickUp] = useState({
        movingDate: '',
        name: '',
        mobile: '',
        address: '',
        pickUpLift: 'yes',
        type: 'NM'
    })
    const [dropOff, setDropOff] = useState({
        address: '',
        info: '',
        lift: 'yes',
        type: 'dropoff'
    })
    const [extraLoc, setExtraLoc] = useState([])

    const [toastState, setToastState] = useState('hidden');
    const [toastText, setToastText] = useState('');

    useEffect(() => {
        axios.get(`${routing}/api/v1/session`, {
            withCredentials: true
        })
        .then(res => {
            if (res.data.data.length === 0) {
                navigate(`/services/furniture`)
            }
        })
    }, [])

    function handleToast(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
        setTimeout(() => {
            setToastState('hidden');
            setToastText('')
        }, 3000)
    }

    function handleToastSuccess(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
    }

    function handlePickUpChange(event) {
        const {name, value} = event.target
        setPickUp({...pickUp, [name]: value})
    }

    function handleDropOffChange(event) {
        const {name, value} = event.target
        setDropOff({...dropOff, [name]: value})
    }

    function handleExtraLocChange(event, ind) {
        const {name, value} = event.target;

        let inputs = extraLoc.slice();
        
        for(let i in inputs){
            if(Number(i) === Number(ind)){
                inputs[i][name] = value;
                setExtraLoc(inputs);
                break;
            }
        }
    }

    function addExtraLoc() {
        setExtraLoc([...extraLoc, {
            address: '',
            info: '',
            lift: 'yes',
            type: 'extraloc'
        }])
        executeScroll();
    }

    function handleDeleteExtraLoc(event, ind) {
        event.preventDefault();
        let tmp_extraLoc = extraLoc.filter((i, index) => index !== Number(ind))
        setExtraLoc(tmp_extraLoc);
    }

    function getaQuote() {
        
        axios.get(`${routing}/api/v1/session`, {
            withCredentials: true
        })
        .then((res) => {
            let data = {
                furniture: res.data.data,
                pickUp: pickUp,
                dropOff: dropOff,
                extraLoc: extraLoc
            }
            let valid = formValidator.furnitureValidator(data.furniture, data.pickUp, data.dropOff, data.extraLoc);
            if (valid) {
                axios.post(`${routing}/api/v1/jobdetails/furniture`, data, {
                    withCredentials: true
                })
                .then((res) => {
                    handleToastSuccess('success', 'Query has been received! We are currently in beta mode and will incorporate WhatsApp confirmation soon!');
                    // navigate(`/services/furniture?action=success`)
                    
                    setPickUp({
                        movingDate: '',
                        name: '',
                        mobile: '',
                        address: '',
                        pickUpLift: 'yes',
                        type: 'NM'
                    });
                    setDropOff({
                        address: '',
                        info: '',
                        lift: 'yes',
                        type: 'dropoff'
                    });
                    setExtraLoc([]);

                    
                })
                .catch(err => {
                    handleToast('error', 'An error has occurred');
                })
            } else {
                handleToast('error', 'Please ensure form is filled');
            }
        })
        .catch(err => {
            handleToast('error', 'An error has occurred');
        })
    }

    return (
        <div>
            <Helmet>
                <title>Aegis Logistic Solutions - Furniture Move Checkout</title>
                <meta name="description" content="Welcome to Aegis Logistic Solutions. We seek to be Singapore's no.1 Mover & Logistic Solutions Provider"/>
                <link rel="canonical" href="https://aegislogistic.solutions/services/furniture/checkout"/>
            </Helmet>
            <Toast toastState={toastState} setToastState={setToastState} toastText={toastText} specialClose={true}/>
            <br/>
            <div className='padding-container'>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <div>
                            <Link to='/services/furniture'>
                                <div>
                                    <IoIcons.IoIosArrowBack className='link'/>
                                    <p className='link'>Back</p>
                                </div>
                            </Link>
                            <br/>
                            <h1>Fill in your details</h1>
                            <br/>
                            <br/>
                            <div style={{textAlign:'center'}}>
                                <img src={"/standardmove.png"} style={{ borderRadius:'2rem', paddingTop:'0'}} className='img-contain' alt="about-us"/>
                            </div>
                            <br/>
                            <br/>
                            <div>
                                <p>Send us your details, and we will get back to via WhatsApp for further confirmation! Thank you for engaging Aegis Logistic Solutions, your support means the world to us &#10084;&#65039;</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <div className='grid-section grid-section-color-1 section-margin-bottom'>
                    <div className='grid-section-overlay'>
                        <div className='section-form-div'>
                        <CheckoutForm pickUp={pickUp} setPickUp={setPickUp} handlePickUpChange={handlePickUpChange}/>
                        </div>
                    </div>
                </div>
                <div className='grid-section grid-section-color-1 section-margin-bottom'>
                    <div className='grid-section-overlay'>
                        <div className='section-form-div'>
                        <DropOffForm dropOff={dropOff} setDropOff={setDropOff} handleDropOffChange={handleDropOffChange}/>
                        </div>
                    </div>
                </div>
                <div>
                {
                    extraLoc.map((el, ind) => {
                        return (
                            <div key={ind} className='grid-section grid-section-color-1 section-margin-bottom'>
                                <div className='grid-section-overlay'>
                                    <div className='section-form-div'>
                                    <ExtraLocForm el={el} ind={ind} handleExtraLocChange={handleExtraLocChange} handleDeleteExtraLoc={handleDeleteExtraLoc}/>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
                <div ref={ref}></div>
                <div>
                    <button className='transparent-btn' onClick={addExtraLoc}>Add Extra Location</button>
                    <br/>
                    <br/>
                    <button className='yellow-btn' onClick={getaQuote}>Get A Quote</button>
                </div>
            </div>
        </div>

    )
}