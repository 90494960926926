import React from 'react';
import { motion } from 'framer-motion';
import scrollvariants from '../../../utils/scrollvariants';

export default function ExtraLocForm({el, ind, handleExtraLocChange, handleDeleteExtraLoc}) {

    return (
        <motion.form initial="offscreen" whileInView="onscreen" variants={scrollvariants.scrollvariantsbottom} viewport={{ once: true }}>
            <h2 className="aegis-font-color-2" style={{float:'left'}}>Extra Location - {ind+1}</h2><h3 onClick={event => handleDeleteExtraLoc(event, ind)} className="error-message-hover" style={{float:'right'}}>x</h3>
            <br/>
            <br/>
            <br/>
            <label htmlFor="address" className="aegis-font-color-1">Extra Location address?</label><span className="error-message">{el.address === '' ? <span> (Required)</span> : null}</span>
            <input type="text" name="address" value={el.address} onChange={event => handleExtraLocChange(event, ind)}/>
            <br/>
            <br/>
            <label htmlFor="lift" className="aegis-font-color-1">Extra Location accessible by lift?</label><span className="error-message">{el.lift === '' ? <span> (Required)</span> : null}</span>
            <br/>
            <br/>
            <label style={{paddingRight:'1rem'}}>
                <input type="radio" name="lift" value='no' onChange={event => handleExtraLocChange(event, ind)} checked={el.lift==='no'}/>
                No
            </label>
            <label>
                <input type="radio" name="lift" value='yes' onChange={event => handleExtraLocChange(event, ind)} checked={el.lift==='yes'}/>
                Yes
            </label>
            <br/>
            <br/>
            <label htmlFor="info" className="aegis-font-color-1">Extra Location Reason?</label><span className="error-message">{el.info === '' ? <span> (Required)</span> : null}</span>
            <textarea name="info" value={el.info} onChange={event => handleExtraLocChange(event, ind)}/>
        </motion.form>
    )
}