export const FaqData = [
    {
        n:"1",
        head: "What is included in the price quoted?",
        body: "Basic disassembly & assembly, Protective wrapping & Manpower from door to door"
    },
    {
        n:"2",
        head: "By when do i need to confirm after receiving a quote?",
        body: "At least 1 week prior to the move subjected to our availability"
    },
    {
        n:"3",
        head: "What if I have more than 1 collection & delivery location?",
        body: "There is an option @ checkout form to add additional pick up and drop off locations. Each additional location is an extra $30"
    },
    {
        n:"4",
        head: "Will you drop my items off immediately after picking up?",
        body: "No as other customers pick ups and drop offs might be within or on the way during your moving job."
    },
    {
        n:"5",
        head: "Is there additional charges for staircase?",
        body: "If the destination is not lift accessible, there would be staircase charges for each item case by case basis."
    },
    {
        n:"6",
        head: "Do you provide disposal services for my existing furniture/appliance?",
        body: "Yes we do provide this service, you can select the move type option with the item in the service form page."
    },
    {
        n:"7",
        head: "Do you provide carton boxes for each move?",
        body: "Yes we do, but up to 10 boxes per load! Subsequent boxes are chargeable at $2 per box."
    },
    {
        n:"8",
        head: "Do you help with assembling of my existing disassembled new/old furniture when delivered to me?",
        body: "No we do not provide this service as we do not manufacture or produce the items you possess, hence we will not assume sufficient knowledge to execute this demand safely without damages."
    },
    {
        n:"9",
        head: "What are the payment terms?",
        body: "We accept paynow (UEN53375125W) & cash."
    },
    {
        n:"10",
        head: "What if my items get damaged?",
        body: "Please drop us an email at aegislogisticsolutions@gmail.com. Please include Booking ID and before and after photos of damages in the email and we will contact you within 1-3 working days."
    },

]