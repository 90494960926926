import React from 'react';

export default function ItemFormNew({type, edit, setEdit, handleEdit, handleChange, handleDelete, addToCart, ind, items, currItem}) {
    return (
        <form>
            <h2 className="aegis-font-color-2" style={{float:'left'}}>New Item</h2><h3 onClick={event => handleDelete(event, ind)} className="error-message-hover" style={{float:'right'}}>x</h3>
            <br/>
            <br/>
            <br/>
            <label htmlFor="item" className="aegis-font-color-1">Name of Item</label><span className="error-message">{items.item === '' ? <span> (Required)</span> : null}</span>
            <input type="text" name="item" value={items.item} onChange={handleChange}/>
            <br/>
            <div className="grid-4-container" style={{gridGap: "0rem", textAlign:'left'}}>
                <div>
                    <label htmlFor="unit" className="aegis-font-color-1">Unit (cm/m/ft)</label><span className="notrequired-message"> (Not Required)</span>
                    <select name="unit" value={items.unit} onChange={handleChange}>
                        {
                        ['cm','m','ft','in'].map((n, i) => {
                            return(
                                <option key={i} value={n}>{n}</option>
                            )
                        })
                        }
                    </select>
                </div>
                <div>
                    <label htmlFor="length" className="aegis-font-color-1">Length (cm)</label><span className="notrequired-message"> (Not Required)</span>
                    <input type="number" name="length" min="0" value={items.length} onChange={handleChange}/>
                </div>
                <div>
                    <label htmlFor="width" className="aegis-font-color-1">Width (cm)</label><span className="notrequired-message"> (Not Required)</span>
                    <input type="number" name="width" min="0" value={items.width} onChange={handleChange}/>
                </div>
                <div>
                    <label htmlFor="height" className="aegis-font-color-1">Height (cm)</label><span className="notrequired-message"> (Not Required)</span>
                    <input type="number" name="height" min="0" value={items.height} onChange={handleChange}/>
                </div>
            </div>
            <br/>
            <label htmlFor="quantity" className="aegis-font-color-1">Quantity</label>
            <input type="number" name="quantity" min="1" value={items.quantity} onChange={handleChange}/>
            <br/>
            <label htmlFor="assembly" className="aegis-font-color-1">Disassembly / Assembly</label>
            <br/>
            <br/>
            <label style={{paddingRight:'1rem'}}>
                <input type="radio" name="assembly" value='no' onChange={handleChange} checked={items.assembly==='no'}/>
                No
            </label>
            <label>
                <input type="radio" name="assembly" value='yes' onChange={handleChange} checked={items.assembly==='yes'}/>
                Yes
            </label>
            <br/>
            <br/>
            <label htmlFor="remarks" className="aegis-font-color-1">Additional Item Info?</label><span className="notrequired-message"> (Not Required)</span>
            <textarea name="remarks" value={items.remarks} onChange={handleChange}/>
        </form>
    )
} 