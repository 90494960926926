import React, {useState} from 'react';
import StorageDisposalForm from './storageDisposalForm';
import axios from "axios";
import Routing from '../../utils/apiRouting';
import Toast from '../../toast';
import formValidator from '../../utils/formValidator';
import { Helmet } from 'react-helmet-async';

export default function StorageDisposal() {
    const routing = Routing();
    const [storageDisposal, setStorageDisposal] = useState({
        mobile: '',
        name: '',
        items: '',
        serviceDate: '',
        lift: 'yes',
        stairs: 0,
        address: '',
        duration: 1,
        durationType: 'month',
        service: 'Storage'
    })

    const [toastState, setToastState] = useState('hidden');
    const [toastText, setToastText] = useState('');

    function handleToast(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
        setTimeout(() => {
            setToastState('hidden');
            setToastText('')
        }, 3000)
    }

    function handleToastSuccess(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
    }

    function handleChange(event) {
        const {name, value} = event.target
        setStorageDisposal({...storageDisposal, [name]: value})
    }

    function getaQuote() {
        let valid = formValidator.storageDisposalValidator(storageDisposal);

        if (valid) {
            axios.post(`${routing}/api/v1/storagedisposal`, storageDisposal, {
                withCredentials: true
            })
            .then((res) => {
                console.log(res);
                handleToastSuccess('success', 'Query has been received! We are currently in beta mode and will incorporate WhatsApp confirmation soon!');
                setStorageDisposal({
                    mobile: '',
                    name: '',
                    items: '',
                    serviceDate: '',
                    lift: 'yes',
                    stairs: 0,
                    address: '',
                    duration: 1,
                    durationType: 'month',
                    service: 'Storage'
                })
            })
            .catch(err => {
                handleToast('error', 'An error has occurred');
            })
        } else {
            handleToast('error', 'Please ensure form is filled');
        }
    }

    return (
        <div>
            <Helmet>
                <title>Aegis Logistic Solutions - Storage & Disposal Service</title>
                <meta name="description" content="Welcome to Aegis Logistic Solutions. We seek to be Singapore's no.1 Mover & Logistic Solutions Provider"/>
                <link rel="canonical" href="https://aegislogistic.solutions/services/storagedisposal"/>
            </Helmet>
            <Toast toastState={toastState} setToastState={setToastState} toastText={toastText}/>
            <br/>
            <div className='padding-container'>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1>Storage & Disposal Service</h1>
                        <br/>
                        <br/>
                        <div style={{textAlign:'center'}}>
                            <img src={"/office.png"} style={{ borderRadius:'2rem'}} className='img-medium' alt="about-us"/>
                        </div>
                        <br/>
                        <br/>
                        <div>
                            <p>Send us your details, and we will get back to via WhatsApp for further confirmation! Thank you for engaging Aegis Logistic Solutions, your support means the world to us &#10084;&#65039;</p>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <StorageDisposalForm storageDisposal={storageDisposal} handleChange={handleChange}/>
                <button className='transparent-btn' onClick={getaQuote}>Get a Quote</button>

            </div>
        </div>
    )
}