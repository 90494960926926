import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Home from './home';
import Navbar from './navbar'
import Furniture from './services/furniture';
import Checkout from './services/furniture/checkout';
import Commercial from './services/commercial';
import Manpower from './services/manpower';
import Residential from './services/residential';
import Storagedisposal from './services/storagedisposal';

import Aboutus from './aegis/aboutus';
import ContactUs from './aegis/contactus';
import JoinUs from './aegis/joinus';
import PartnerUs from './aegis/partnerus';
// import Tenft from './aegis/10ft';
// import Fourteenft from './aegis/14ft';

import { Footer } from './footer/Footer';
import { useEffect } from "react";

function App() {

  function ScrollToTop() {
    const { pathname } = useLocation();
    
    useEffect(() => {
      // if (pathname !== "/services/standard" && pathname !== "/services/standard/checkout") {
      //   window.scrollTo(0, 0);
      // } else {
      //   document.getElementById("form").scrollIntoView({behavior:'smooth',block:'center'})
      // }
      
      setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
      }, 0)
    }, [pathname]);
    return null;
  }

  return (
    <Router>
      <Navbar/>
      <ScrollToTop/>
      <Routes>
        <Route path='/' exact element={<Home/>} />

        <Route path='/services/furniture' exact element={<Furniture/>} />
        <Route path='/services/furniture/checkout' exact element={<Checkout/>} />
        <Route path='/services/residential' exact element={<Residential/>} />
        <Route path='/services/commercial' exact element={<Commercial/>} />
        <Route path='/services/manpower' exact element={<Manpower/>} />
        <Route path='/services/storagedisposal' exact element={<Storagedisposal/>} />

        <Route path='/aegis/contactus' exact element={<ContactUs/>} />
        <Route path='/aegis/partnerus' exact element={<PartnerUs/>} />
        <Route path='/aegis/joinus' exact element={<JoinUs/>} />
        <Route path='/aegis/aboutus' exact element={<Aboutus/>} />
        {/* <Route path='/aegis/10ft' exact element={<Tenft/>} />
        <Route path='/aegis/14ft' exact element={<Fourteenft/>} /> */}

          {/* <Route path='/privacypolicy' exact element={<PrivacyPolicy/>} /> */}
      </Routes>
      <Footer/>
    </Router>

  );
}

export default App;
