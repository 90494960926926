import React from 'react';
import {ReviewsData} from './ReviewsData'
import {Swiper, SwiperSlide} from "swiper/react"
import "swiper/css";
import "swiper/css/effect-cards";

import { EffectCards } from "swiper";

export default function Reviews() {
    function SwiperContainerStyle() {
        return {
          overflowX: "hidden",
          paddingBottom: "3rem",
          paddingTop: "3rem"
        }
      }
    
      function SwiperStyle() {
        return {
          height: "430px",
          width: "250px"
        }
      }
    
      function SwiperSlideStyle() {
        return {
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "18px",
          color: "black",
          padding: "1.2rem",
          backgroundColor: "#faf9d5",
          cursor: "grab",
          userSelect: "none"
        }
      }

      return (
        <>
            <div>
                <img src={"/reviews.png"} className='img-contain' alt="how-it-works"/>
            </div>
            <div className='padding-container grid-section-color-4'>
                <div style={ SwiperContainerStyle() }>
                    <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="mySwiper"
                    style={ SwiperStyle() }
                    >
                    {ReviewsData.map((review, index) => {
                        return (
                            <SwiperSlide key={index} style={ SwiperSlideStyle() }>
                                {review.quote}
                                <p>{review.content}</p><br/>
                                {review.star}{review.star}{review.star}{review.star}{review.star}<br/>
                                <h4>{review.user}</h4>
                            </SwiperSlide>
                        )
                    })}
                    </Swiper>
                </div> 
            </div>
        </>
      )
}