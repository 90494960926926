import React, {useState, useEffect} from 'react';
import {  useNavigate } from 'react-router-dom';

export default function Toast({toastState, setToastState, toastText, specialClose}) {
    const [toastClass, setToastClass] = useState('toast-wrapper-hidden');
    const navigate = useNavigate()
    useEffect(() => {
        if (toastState === 'error') {
            setToastClass('toast-wrapper-error')
        } else if (toastState === 'hidden') {
            setToastClass('toast-wrapper-hidden')
        } else if (toastState === 'success') {
            setToastClass('toast-wrapper-success')
        }

    }, [toastState])
 
    function handleClose(event) {
        event.preventDefault();
        setToastState('hidden');
        if (specialClose === true) {
            navigate(`/services/furniture?action=success`)
        }
    }
    
    return (

        <div className={toastClass}>
            <div className='toast-div'>
                <p>{toastText}</p>
            </div>
            <div className='toast-button-div'>
                {
                    (toastState === 'success') &&
                    <button className='yellow-btn' onClick={handleClose}>Okay</button>
                }
            </div>

        </div>
    )
}