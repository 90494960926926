import React from 'react';
import scrollvariants from '../../utils/scrollvariants';
import { motion } from 'framer-motion';

export default function ManpowerForm({manpower, handleChange}) {
    return (
        <motion.div initial="offscreen" whileInView="onscreen" variants={scrollvariants.scrollvariantsbottom} viewport={{ once: true }}>
            <div className='grid-section grid-section-color-1 section-margin-bottom'>
                <div className='grid-section-overlay'>
                    <div className='section-form-div'>
                        <h2 className="aegis-font-color-2 input-inline">Need Strong Aegis Hands?</h2>
                        <br/>
                        <br/>
                        <label htmlFor="name" className="aegis-font-color-1">How do we address you?</label><span className="error-message">{manpower.name === '' ? <span> (Required)</span> : null}</span>
                        <input type="text" name="name" value={manpower.name} onChange={handleChange}/>
                        <br/>
                        <label htmlFor="mobile" className="aegis-font-color-1">How do we contact(mobile) you?</label><span className="error-message">{manpower.mobile === '' ? <span> (Required)</span> : null}</span>
                        <input type="text" name="mobile" value={manpower.mobile} onChange={handleChange}/>
                        <br/>
                        <label htmlFor="address" className="aegis-font-color-1">Where do we pick up your items?</label><span className="error-message">{manpower.address === '' ? <span> (Required)</span> : null}</span>
                        <input type="text" name="address" value={manpower.address} onChange={handleChange}/>
                        <br/>
                        <label htmlFor="hireDate" className="aegis-font-color-1">Hire Date</label>
                        <span className="error-message">{manpower.hireDate === '' ? <span> (Required)</span> : null}</span>
                        <span className="error-message">{Date.parse(manpower.hireDate) < new Date().setDate(new Date().getDate() + 1) ? <span> (For urgent help, reach us via WhatsApp)</span> : null}</span>
                        <input type="datetime-local" name="hireDate" value={manpower.hireDate} onChange={handleChange}/>
                        <br/>

                        <label htmlFor="numPax" className="aegis-font-color-1">Number of Pax Needed</label><span className="error-message">{manpower.numPax === '' ? <span> (Required)</span> : null}</span>
                        <select name="numPax" value={manpower.numPax} onChange={handleChange}>
                            {
                                [...Array(31).keys()].filter(x => x >= 0).map((n, i) => {
                                    return(
                                        <option key={i} value={n}>{n}</option>
                                    )
                                })
                            }
                        </select>
                        <br/>
                        <label htmlFor="duration" className="aegis-font-color-1">Duration (hours)</label><span className="error-message">{manpower.duration === '' ? <span> (Required)</span> : null}</span>
                        <select name="duration" value={manpower.duration} onChange={handleChange}>
                            {
                                [...Array(31).keys()].filter(x => x >= 0).map((n, i) => {
                                    return(
                                        <option key={i} value={n}>{n}</option>
                                    )
                                })
                            }
                        </select>
                        <br/>
                        <label htmlFor="equipment" className="aegis-font-color-1">Equipments needed?</label><span className="error-message">{manpower.equipment === '' ? <span> (Required)</span> : null}</span>
                        <textarea name="equipment" value={manpower.equipment} onChange={handleChange}/>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}