import React, {useState} from 'react';
import scrollVariants from '../utils/scrollvariants';
import { motion } from 'framer-motion';
import axios from "axios";
import Routing from '../utils/apiRouting';
import Toast from '../toast';
import formValidator from '../utils/formValidator';
import { Helmet } from 'react-helmet-async';

export default function PartnerUs() {
    const routing = Routing();
    const [query, setQuery] = useState({
        type: 'partnerus',
        name:'',
        industry:'',
        email:'',
        contact:'',
        message:''
    });

    const [toastState, setToastState] = useState('hidden');
    const [toastText, setToastText] = useState('');

    function handleToast(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
        setTimeout(() => {
            setToastState('hidden');
            setToastText('')
        }, 3000)
    }

    function handleToastSuccess(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
    }

    function handleChange(event) {
        const {name, value} = event.target
        setQuery({...query, [name]: value})
    }

    function enquire(event) {
        event.preventDefault();
        let valid = formValidator.queryValidator(query);
        if (valid) {
            axios.post(`${routing}/api/v1/query`, query, {
                withCredentials: true
            })
            .then((res) => {
                handleToastSuccess('success', 'Query has been received!');
                setQuery({
                    type: 'partnerus',
                    name:'',
                    industry:'',
                    email:'',
                    contact:'',
                    message:''
                })
            })
            .catch((error) => {
                handleToast('error', 'An error has occurred');
            })
        } else {
            handleToast('error', 'Please ensure form is filled');
        }

    }
    return (
        <div>
            <Helmet>
                <title>Aegis Logistic Solutions - Partner Us</title>
                <meta name="description" content="Welcome to Aegis Logistic Solutions. We seek to be Singapore's no.1 Mover & Logistic Solutions Provider"/>
                <link rel="canonical" href="https://aegislogistic.solutions/aegis/partnerus"/>
            </Helmet>
            <Toast toastState={toastState} setToastState={setToastState} toastText={toastText}/>
            <div className='padding-container'>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1 className='aegis-font-color-2'>Partner Us</h1>
                        <br/>
                        <p>Are you a Real-Estate Agent? Wholesaler? Furniture Retail? Start-Up? Or anyone really, B2B/B2C...as long as you need logistical solutions, get in touch with us!</p>
                        <br/>
                        <div style={{textAlign:'center'}}>
                            <img src={"/landing.png"} style={{ borderRadius:'2rem'}} className='img-medium' alt="about-us"/>
                        </div>
                    </div>
                </div>
            </div>
            <motion.div className='padding-container div-bg-color-2' initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <div className='grid-section div-bg-color-3'>
                    <div className='grid-section-overlay'>
                        <h1 className='aegis-font-color-2'>#WorkWithUs</h1>
                        <p>Why partner Aegis?</p>
                        <br/>
                        <br/>
                        <div className='grid-2-container'>
                            <div className='card-grid-section grid-section-color-2'>
                                <div className='card-grid-section-2'>
                                    <div style={{padding:'2rem'}}>
                                        <br/>
                                        <h1>Logistical Peace</h1>
                                        <br/><h1 style={{'fontSize':'100px'}}>&#128230;</h1><br/>
                                    </div>

                                    <div className='section-footer grid-section-color-4' style={{borderBottomLeftRadius:'2rem', borderBottomRightRadius:'2rem',padding:'2rem'}}>
                                        <p className='aegis-font-white'>You can focus on <span className="aegis-font-color">Growth</span> while we provide manpower, training, fleet management &amp; even assembly of products.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card-grid-section grid-section-color-2'>
                                <div className='card-grid-section-2'>
                                    <div style={{padding:'2rem'}}>
                                        <br/>
                                        <h1>Dedicated Marketing</h1>
                                        <br/><h1 style={{'fontSize':'100px'}}>&#128226;</h1><br/>
                                    </div>

                                    <div className='grid-section-color-4' style={{borderBottomLeftRadius:'2rem', borderBottomRightRadius:'2rem',padding:'2rem'}}>
                                        <p className='aegis-font-white'>We believe that for every partnership, it should better each other. In depth <span className="aegis-font-color">Dedicated</span> marketing details will be shared upon request.</p>
                                    </div>
                                </div>
                            </div>
                            <div className=' card-grid-section grid-section-color-2'>
                                <div className='card-grid-section-2'>
                                    <div style={{padding:'2rem'}}>
                                        <br/>
                                        <h1>Brand Exposure</h1>
                                        <br/><h1 style={{'fontSize':'100px'}}>&#127759;</h1><br/>
                                    </div>

                                    <div className='grid-section-color-4' style={{borderBottomLeftRadius:'2rem', borderBottomRightRadius:'2rem',padding:'2rem'}}>
                                        <p className='aegis-font-white'><span className="aegis-font-color">Your Company</span> have the privilege of expanding your customer horizon and reach the masses by partnering us. Sharing is Caring!</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card-grid-section grid-section-color-2'>
                                <div className='card-grid-section-2'>
                                    <div style={{padding:'2rem'}}>
                                        <br/>
                                        <h1>Cost Savings</h1>
                                        <br/><h1 style={{'fontSize':'100px'}}>&#128200;</h1><br/>
                                    </div>

                                    <div className='grid-section-color-4' style={{borderBottomLeftRadius:'2rem', borderBottomRightRadius:'2rem',padding:'2rem'}}>
                                        <p className='aegis-font-white'>Why <span className="aegis-font-color">Focus</span> on maintaining a transportation fleet and stress over manpower hiring and training? Leave these cost + time incurred activities to us!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
            <motion.div className='padding-container' initial="offscreen" whileInView="onscreen" variants={scrollVariants.scrollvariantsbottom} viewport={{ once: true }}>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1 className='aegis-font-color-2'>Reach Us!</h1>
                        <br/>
                        <p>Drop us an enquiry and we will get in touch with you~</p>
                        <br/>
                        <br/>
                        <div>
                            <label htmlFor="industry" className="aegis-font-color-1">What's your industry?</label><span className="error-message">{query.industry === '' ? <span> (Required)</span> : null}</span>
                            <input type="text" name="industry" value={query.industry} onChange={handleChange}/>

                            <label htmlFor="name" className="aegis-font-color-1">How do we address you?</label><span className="error-message">{query.name === '' ? <span> (Required)</span> : null}</span>
                            <input type="text" name="name" value={query.name} onChange={handleChange}/>

                            <label htmlFor="contact" className="aegis-font-color-1">What's your number?</label><span className="error-message">{query.contact === '' ? <span> (Required)</span> : null}</span>
                            <input type="text" name="contact" value={query.contact} onChange={handleChange}/>

                            <label htmlFor="email" className="aegis-font-color-1">What's your email?</label><span className="error-message">{query.email === '' ? <span> (Required)</span> : null}</span>
                            <input type="text" name="email" value={query.email} onChange={handleChange}/>

                            <label htmlFor="message" className="aegis-font-color-1">Leave us a message!</label><span className="error-message">{query.message === '' ? <span> (Required)</span> : null}</span>
                            <textarea name="message" value={query.message} onChange={handleChange}/>

                            <button className='transparent-btn' onClick={enquire}>Enquire Now</button>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>

    )
}