import React, {useState} from 'react';
import ManpowerForm from './manpowerForm';
import axios from "axios";
import Routing from '../../utils/apiRouting';
import Toast from '../../toast';
import formValidator from '../../utils/formValidator'
import { Helmet } from 'react-helmet-async';

export default function Manpower() {
    const routing = Routing();
    const [manpower, setManpower] = useState({
        numPax: 2,
        hireDate: '',
        duration: 2,
        equipment: '',
        mobile: '',
        name: '',
        address: ''
    })

    const [toastState, setToastState] = useState('hidden');
    const [toastText, setToastText] = useState('');

    function handleToast(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
        setTimeout(() => {
            setToastState('hidden');
            setToastText('')
        }, 3000)
    }

    function handleToastSuccess(toastState, toastText) {
        setToastState(toastState);
        setToastText(toastText);
    }

    function handleChange(event) {
        const {name, value} = event.target
        setManpower({...manpower, [name]: value})
    }

    function getaQuote() {
        let valid = formValidator.manpowerValidator(manpower)
        if (! valid) {
            handleToast('error', 'Please ensure form is filled');
        } else {
            axios.post(`${routing}/api/v1/manpower`, manpower, {
                withCredentials: true
            })
            .then((res) => {
                console.log(res);
                handleToastSuccess('success', 'Query has been received! We are currently in beta mode and will incorporate WhatsApp confirmation soon!');
                setManpower({
                    numPax: 2,
                    hireDate: '',
                    duration: 2,
                    equipment: '',
                    mobile: '',
                    name: '',
                    address: ''
                })
            })
            .catch(err => {
                handleToast('error', 'An error has occurred');
            })
        }
    }

    return (
        <div>
            <Helmet>
                <title>Aegis Logistic Solutions - Manpower service</title>
                <meta name="description" content="Welcome to Aegis Logistic Solutions. We seek to be Singapore's no.1 Mover & Logistic Solutions Provider"/>
                <link rel="canonical" href="https://aegislogistic.solutions/services/manpower"/>
            </Helmet>
            <Toast toastState={toastState} setToastState={setToastState} toastText={toastText}/>
            <br/>
            <div className='padding-container'>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1>Manpower Service</h1>
                        <br/>
                        <br/>
                        <div style={{textAlign:'center'}}>
                            <img src={"/wemove.jpg"} style={{ borderRadius:'2rem'}} className='img-medium' alt="about-us"/>
                        </div>
                        <br/>
                        <br/>
                        <div>
                            <p>Send us your details, and we will get back to via WhatsApp for further confirmation! Thank you for engaging Aegis Logistic Solutions, your support means the world to us &#10084;&#65039;</p>
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <ManpowerForm manpower={manpower} handleChange={handleChange}/>
                <button className='transparent-btn' onClick={getaQuote}>Get a Quote</button>

            </div>
        </div>
    )
}