import React from "react";
import { Link } from 'react-router-dom';

export const Footer = () => {
    return (
        <div className='footer'>
            <div className='footerContainer'>
            <div className='footerLeft'>
                <p>© Aegis Logistic Solutions (2023)</p>
                <br/>
                <br/>
            </div>
            <div className='footerRight'>
                <div className='footerGrid'>
                    <div className='footerGridItem'>
                        <h4>Aegis Logistic Solutions</h4>
                        <p>Operating Hours:</p>
                        <p>Monday to Saturday: 9am-9pm</p>
                        <a href="tel:81012340" style={{fontSize:"1rem",textDecoration:"none"}}>81012340</a><br/>
                        <a href="mailto:aegislogisticsolutions@gmail.com" style={{textDecoration:"none"}}> <span className="aegis-font-color">aegislogisticsolutions</span>@gmail.com</a><br/>
                        <Link to='/privacypolicy'>Privacy Policy</Link><br/><br/>
                    </div>
                    <div className='footerGridItem'>
                        <h4>Services</h4>
                        <a href='http://api.whatsapp.com/send?phone=6581012340' style={{textDecoration:"none"}} target="_blank" rel="noreferrer"><p className="aegis-font-color" style={{cursor:"pointer"}}>Urgent Moving</p></a>
                        <Link to='/services/furniture'>Furniture Moving</Link><br/>
                        <Link to='/services/residential'>Residential Relocation</Link><br/>
                        <Link to='/services/commercial'>Commercial Relocation</Link><br/>
                        <Link to='/services/manpower'>Manpower Service</Link><br/>
                        <Link to='/services/storagedisposal'>Storage & Disposal</Link><br/>
                    </div>
                    <div className='footerGridItem'>
                        <h4>Trust in us</h4>
                        <Link to='/aegis/aboutus'>About us</Link><br/>
                        <Link to='/aegis/contact'>Contact us</Link><br/>
                        <Link to='/aegis/partnerus'>Partner us</Link><br/>
                        <Link to='/aegis/joinus'>Join Us</Link><br/>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
}