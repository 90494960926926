import React from 'react'
import * as FaIcons from 'react-icons/fa';

export const ReviewsData = [
    {
        quote: <FaIcons.FaQuoteRight/>,
        content: "Have a last min deal as one of the movers cancelled my appointment. So they help me to collect my item at the last min. Very prompt reply and straight forward quotation. Happy with their service. My tabletop is wrap and not a single damaged. Reasonable price",
        star: <FaIcons.FaStar style={{"color": "#f6c058"}}/>,
        user: "- Ash Shahid, Google"
    },
    {
        quote: <FaIcons.FaQuoteRight/>,
        content: "Responsive sales, and price is reasonable compared to other moving companies. Moving house is hectic enough, so having a good mover is great. The movers handled our items professionally, and they were also nice and quick",
        star: <FaIcons.FaStar style={{"color": "#f6c058"}}/>,
        user: "- -GV Nithin, Google",
    },
    {
        quote: <FaIcons.FaQuoteRight/>,
        content: "Fuss free, able to accomodate to my request at the very last minute and PH too. Came early for the move and item delivered safe and sound without any issues. Quick replies on their whatsapp too. Price is also reasonable. Will not hesitate to engage your services next time. Highly recommended. Thanks!",
        star: <FaIcons.FaStar style={{"color": "#f6c058"}}/>,
        user: "- Ndhrh Kmrh, Google",
    },
    {
        quote: <FaIcons.FaQuoteRight/>,
        content: "Contacted Aegis last minute to help move fridge from office to home. They were quick to respond and accomodating to take up the job. The 2 workers were very efficient, and listen to our instructions when moving the fridge. Will reach out to Aegis if i need to relocate my bulky furniture in future",
        star: <FaIcons.FaStar style={{"color": "#f6c058"}}/>,
        user: "- TKM, Google",
    },
    {
        quote: <FaIcons.FaQuoteRight/>,
        content: "Smooth process from booking to delivery day. Driver was on time and handle furniture well, even wrapped it in plastic for protection. Price is reasonable too",
        star: <FaIcons.FaStar style={{"color": "#f6c058"}}/>,
        user: "- Kate Lo KW, Google",
    },
    {
        quote: <FaIcons.FaQuoteRight/>,
        content: "Kudos to the team for the help in moving my house. Pricing was fair & transparent. Even though some items were really heavy, the guys didnt complain and still had a smile on their faces. Really appreciate your service. highly recommended!",
        star: <FaIcons.FaStar style={{"color": "#f6c058"}}/>,
        user: "- Josiah Josiah, Google",
    },
    {
        quote: <FaIcons.FaQuoteRight/>,
        content: "I'm thankful i found Aegis logistics who was very responsive from the beginning, punctual, rates were definitely competitive and the service was wonderful. Highly recommended.Thank you again for the service!",
        star: <FaIcons.FaStar style={{"color": "#f6c058"}}/>,
        user: "- Veranga Wijesinghe, Google",
    },
    {
        quote: <FaIcons.FaQuoteRight/>,
        content: "A job well done to Aegis Logistic Solutions ! Thank you so much for your amazing service ! Though the movers were a little bit late, they handled 2 of my fragile Vanity sets with care from the collecting location to delivering location. Super affordable & reasonable price quotation! Highly recommended! Will definitely engage your service again next time! 💯👍🏽",
        star: <FaIcons.FaStar style={{"color": "#f6c058"}}/>,
        user: "- Samantha Soria, Google",
    },
    {
        quote: <FaIcons.FaQuoteRight/>,
        content: "Highly reliable, and totally professional and prompt logistic provider. Will definitely deploy Aegis again for my valuables items, Aegis never fail!",
        star: <FaIcons.FaStar style={{"color": "#f6c058"}}/>,
        user: "- Sáe Cheng, Google",
    },
    {
        quote: <FaIcons.FaQuoteRight/>,
        content: "Helped me move 3 times already with all my furniture and belongings intact without any scratches / damages. They’re definitely the best out there, impeccable service with superb customer support, this is why I always holla at them whenever I’m moving! 1000% recommended.",
        star: <FaIcons.FaStar style={{"color": "#f6c058"}}/>,
        user: "- MoneyNoProb RS, Google",
    },
]