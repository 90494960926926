import React from 'react';

const Howitworks = () => {
    return (
        <div className='padding-container grid-section-color-4'>
            <div className='grid-section grid-section-color-1'>
                <div className='grid-section-overlay'>
                    <h1 className="aegis-font-color-2">#HowItWorks</h1>
                    <h3 className="aegis-font-color-1">Aegis Logistic Solutions</h3>
                    <div >
                        <img src={"/howItWorks.png"} className='img-contain' alt="how-it-works"/>
                    </div>
                    <div className='grid-3-container'>
                        <div>
                            <h1 className="aegis-font-color-2">1.</h1>
                            <p>Add items to Lorry, fill in your particulars and moving details using the form</p>
                        </div>
                        <div>
                            <h1 className="aegis-font-color-2">2.</h1>
                            <p>We will contact you via WhatsApp with a quote to finalise the move</p>
                        </div>
                        <div>
                            <h1 className="aegis-font-color-2">3.</h1>
                            <p>Leave everything to us, satisfaction guaranteed</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Howitworks;