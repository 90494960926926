import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import * as BiIcons from 'react-icons/bi';
import {useCycle} from 'framer-motion';
import NavMenu from './NavMenu';

const Navbar = () => {
    const [isVisible, onCycle] = useCycle(false, true);
    const ref = useRef(null)

    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isVisible && ref.current && !ref.current.contains(e.target)) {
                onCycle()
            }
        }
        document.addEventListener("click", checkIfClickedOutside)
        return () => {
          document.removeEventListener("click", checkIfClickedOutside)
        }
      }, [isVisible])

    function cycleCheck() {
        if (isVisible === true) {
            onCycle()
        }
    }

    return (
        <div className='navbar-container' >
            <div className='navbar' style={{backgroundColor: isVisible ? '#222' : '#F6F4E6'}}>
                <div className='navbar-header'>
                    <Link to='/' >
                        <div className='navbar-left' onClick={cycleCheck}>
                            <img src={"/aegisLogo.png"} className='navbar-logo' alt="aegis-logistic-solutions"/>
                        </div>
                    </Link>
                    <div className='navbar-menu-icon' ref={ref}>
                        <BiIcons.BiMenuAltRight onClick={onCycle}/>
                        <br/>
                    </div>
                </div>
                        
                <div>
                    <NavMenu isVisible={isVisible} onCycle={onCycle}/>
                </div>
            </div>

        </div>
    )
}

export default Navbar;