import React from 'react';

export default function ItemFormExistingSummary({type, edit, setEdit, handleEdit, handleChange, handleDelete, addToCart, ind, items, currItem, handleChangeMulti, allItems}) {
    return (
        <div>
            <h2 className="aegis-font-color-2">Item {ind+1}</h2>
            <br/>
            <h1>{currItem.item}</h1>
            <br/>
            <h3>L x W x H</h3>
            <p>{currItem.length ? currItem.length : 'null'} x {currItem.width ? currItem.width : 'null'} x {currItem.height ? currItem.height : 'null'}</p>
            <br/>
            <button type='button' className='transparent-btn' onClick={() => setEdit(ind)}>Edit</button>
        </div>
    )
}