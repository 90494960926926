import React from 'react';
import ItemFormBlockExistingSummary from './itemFormExistingSummary';

export default function ItemFormExisting({type, edit, setEdit, handleEdit, handleChange, handleDelete, addToCart, ind, items, currItem, handleChangeMulti, allItems}) {
    return (
        <>
        {
            ind === edit &&
            <form>
                <h2 className="aegis-font-color-2" style={{float:'left'}}>Item {ind+1}</h2><h3 onClick={event => handleDelete(event, ind)} className="error-message-hover" style={{float:'right'}}>x</h3>
                <br/>
                <br/>
                <br/>
                <label htmlFor="item" className="aegis-font-color-1">Name of Item</label><span className="error-message">{currItem.item === '' ? <span> (Required)</span> : null}</span>
                <input type="text" name="item" value={currItem.item} onChange={event => handleChangeMulti(event, ind)}/>
                <br/>
                <div className="grid-4-container" style={{gridGap: "0rem", textAlign:'left'}}>
                    <div>
                        <label htmlFor="unit" className="aegis-font-color-1">Unit (cm/m/ft)</label>
                        <select name="unit" value={currItem.unit} onChange={event => handleChangeMulti(event, ind)}>
                            {
                            ['cm','m','ft','in'].map((n, i) => {
                                return(
                                    <option key={i} value={n}>{n}</option>
                                )
                            })
                            }
                        </select>
                    </div>
                    <div>
                        <label htmlFor="length" className="aegis-font-color-1">Length (cm)</label>
                        <input type="number" name="length" min="1" value={allItems[ind]['length']} onChange={event => handleChangeMulti(event, ind)}/>
                    </div>
                    <div>
                        <label htmlFor="width" className="aegis-font-color-1">Width (cm)</label>
                        <input type="number" name="width" min="1" value={allItems[ind]['width']} onChange={event => handleChangeMulti(event, ind)}/>
                    </div>
                    <div>
                        <label htmlFor="height" className="aegis-font-color-1">Height (cm)</label>
                        <input type="number" name="height" min="1" value={allItems[ind]['height']} onChange={event => handleChangeMulti(event, ind)}/>
                    </div>
                </div>
                <br/>
                <label htmlFor="quantity" className="aegis-font-color-1">Quantity</label>
                <input type="number" name="quantity" min="1" value={currItem.quantity} onChange={handleChange}/>
                <br/>
                <label htmlFor="assembly" className="aegis-word">Disassembly / Assembly</label>
                <br/>
                <br/>
                <label style={{paddingRight:'1rem'}}>
                    <input type="radio" name="assembly" value='no' onChange={event => handleChangeMulti(event, ind)} checked={currItem.assembly==='no'}/>
                    No
                </label>
                <label>
                    <input type="radio" name="assembly" value='yes' onChange={event => handleChangeMulti(event, ind)} checked={currItem.assembly==='yes'}/>
                    Yes
                </label>
                <br/>
                <br/>
                <label htmlFor="remarks" className="aegis-word">Additional Item Info?</label><span className="notrequired-message"> (Not Required)</span>
                <textarea name="remarks" className="" value={allItems[ind]['remarks']} onChange={event => handleChangeMulti(event, ind)}/>
                <button type='button' className='transparent-btn' onClick={event => handleEdit(event, ind)}>Save</button>
            </form>
        }
        {
            edit !== ind && 
            <ItemFormBlockExistingSummary type={type} edit={edit} setEdit={setEdit} handleChange={handleChange} handleDelete={handleDelete} addToCart={addToCart} ind={ind} items={items} currItem={currItem} handleChangeMulti={handleChangeMulti}/>

        }
    </>
    )
}