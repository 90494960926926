import React from 'react';

const Featured = () => {
    return (
        <a target='_blank' rel="noreferrer" href='https://threebestrated.sg/moving-companies-in-tampines'>
            <div className='padding-container div-bg-color-1'>
                <div className='grid-section grid-section-color-1'>
                    <div className='grid-section-overlay'>
                        <h1 className="aegis-font-color-2">#Featured</h1>
                        <h3 className="aegis-font-color-1">Aegis Logistic Solutions</h3>
                        <div style={{textAlign: 'center'}}>
                            <img src={"/threebest.png"} style={{maxHeight: '200px', maxWidth:'300px'}} className='img-contain' alt="how-it-works"/>
                        </div>
                        <br/>
                        <div>
                            <h2 style={{color:'#000'}}>Click To Read!</h2>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default Featured;