let itemValidator = (data) => {
    if (data.filter(d => d.item === '').length > 0) {
        return false;
    } 
    if (data.filter(d => (d.quantity === '' || Number(d.quantity) < 1)).length > 0) {
        return false;
    }
    if (data.filter(d => d.service === '').length > 0) {
        return false;
    }
    // if no error
    return data;

}

let  furnitureValidator = (items, pickUp, dropOff, extraLoc) => {
    if (pickUp.movingDate === '' || Date.parse(pickUp.movingDate) < new Date().setDate(new Date().getDate() + 1) || pickUp.name === '' || pickUp.mobile === '' || pickUp.address === '' || pickUp.pickUpLift === '') {
        return false;
    } else if (dropOff.address === '' || dropOff.lift === '') {
        return false;
    } else if (extraLoc.filter(el => (el.address === '' || el.lift === '' || el.info === '')).length > 0) {
        return false
    } else {
        for (let i in items) {
            if (items[i]['length'] === '' && items[i]['width'] === '' && items[i]['height'] === '') {
                delete items[i]['unit'];
            }
            if (items[i]['length'] === '') {
                delete items[i]['length'];
            }
            if (items[i]['width'] === '') {
                delete items[i]['width'];
            }
            if (items[i]['height'] === '') {
                delete items[i]['height'];
            }
            if (items[i]['unit'] === '') {
                delete items[i]['unit'];
            }

        }
    }

    return {
        items: items,
        pickUp: pickUp,
        dropOff: dropOff,
        extraLoc: extraLoc
    };
}

let residentialValidator = (residential) => {
    if (residential.currPropertyType === '' || residential.numRooms === '' || residential.numRooms <= 0 || residential.floor === '' ||
    residential.floor <= 0 || residential.stairs === '' || residential.stairs < 0 || residential.name === '' ||
    residential.mobile === '' || residential.item === '' || residential.movingDate === '' || Date.parse(residential.movingDate) < new Date().setDate(new Date().getDate() + 1) || 
    residential.boxes === '' || residential.address === '' || residential.lift === '' || residential.dropoffAddress === '' || residential.dropoffLift === '') {
        return false

    } 
    if (residential.boxes > 0) {
        if (residential.boxesDeliver === '') {
            return false;
        }
        if (Date.parse(residential.boxesDeliver) >= Date.parse(residential.movingDate)) {
            return false
        }
    }
    
    
    return residential
}

let manpowerValidator = (manpower) => {
    if (manpower.numPax === '' || manpower.numPax <= 0 || 
        manpower.hireDate === '' || Date.parse(manpower.hireDate) < new Date().setDate(new Date().getDate() + 1) ||
        manpower.duration === '' || manpower.duration <= 0 ||
        manpower.equipment === '' || manpower.mobile === '' || manpower.name === '' || manpower.address === '') {
        return false
    }
    return manpower
}

let storageDisposalValidator = (storageDisposal) => {
    if (storageDisposal.service === 'Storage') {
        if (storageDisposal.items === '' || storageDisposal.numPax <= 0 || 
            storageDisposal.serviceDate === '' || Date.parse(storageDisposal.serviceDate) < new Date().setDate(new Date().getDate() + 1) ||
            storageDisposal.lift === '' || storageDisposal.stairs < 0 || storageDisposal.stairs === '' ||
            storageDisposal.address === '' || storageDisposal.duration <= 0 || storageDisposal.duration === '' ||
            storageDisposal.durationType === '' || storageDisposal.service === '' || storageDisposal.mobile === '' || storageDisposal.name === ''
        ) {
            return false
        }
    } else if (storageDisposal.service === 'Disposal') {
        if (storageDisposal.items === '' || storageDisposal.serviceDate === '' || Date.parse(storageDisposal.serviceDate) < new Date().setDate(new Date().getDate() + 1) ||
            storageDisposal.lift === '' || storageDisposal.stairs < 0 || storageDisposal.stairs === '' ||
            storageDisposal.address === '' || storageDisposal.service === '' || storageDisposal.mobile === '' || storageDisposal.name === ''
        ) {
            return false
        }
    }

    return storageDisposal
}
let queryValidator = (queryMsg) => {
    if (queryMsg.type === 'partnerus') {
        if (queryMsg.name === '' || queryMsg.industry === '' || queryMsg.email === '' || queryMsg.contact === '' || queryMsg.message === '') {
            return false
        }
    } else if (queryMsg.type === 'joinus') {
        if (queryMsg.name === '' || queryMsg.email === '' || queryMsg.contact === '' || queryMsg.message === '') {
            return false
        }
    }
    return queryMsg;
}

let formValidator = {
    itemValidator: itemValidator,
    furnitureValidator: furnitureValidator,
    residentialValidator: residentialValidator,
    manpowerValidator: manpowerValidator,
    storageDisposalValidator: storageDisposalValidator,
    queryValidator: queryValidator
}

export default formValidator;
