import React from 'react';
import scrollvariants from '../../utils/scrollvariants';
import { motion } from 'framer-motion';

export default function ResidentialForm({residential, handleChange}) {
    return (
        <motion.div initial="offscreen" whileInView="onscreen" variants={scrollvariants.scrollvariantsbottom} viewport={{ once: true }}>
            <div className='grid-section grid-section-color-1 section-margin-bottom'>
                <div className='grid-section-overlay'>
                    <div className='section-form-div'>
                        <div>
                            <h2 className="aegis-font-color-2 input-inline">Tell us more about you</h2>
                            <br/>
                            <br/>
                            <label htmlFor="name" className="aegis-font-color-1">How do we address you?</label><span className="error-message">{residential.name === '' ? <span> (Required)</span> : null}</span>
                            <input type="text" name="name" value={residential.name} onChange={handleChange}/>

                            <label htmlFor="mobile" className="aegis-font-color-1">How do we contact (mobile) you?</label><span className="error-message">{residential.mobile === '' ? <span> (Required)</span> : null}</span>
                            <input type="text" name="mobile" value={residential.mobile} onChange={handleChange}/>

                            <span><p className="aegis-font-color-2 select-inline">I stay in a/an&nbsp;</p></span><span className="error-message">{residential.currPropertyType === '' ? <span> (Required)</span> : null}</span>
                            <select className='select-inline' name="currPropertyType" value={residential.currPropertyType} onChange={handleChange}>
                                <option value='HDB'>HDB</option>
                                <option value='CONDO'>CONDO</option>
                                <option value='APARTMENT'>APARTMENT</option>
                                <option value='LANDED'>LANDED</option>
                            </select>
                        </div>
                        <div>
                            <span><p className="aegis-font-color-2 input-inline">My property has &nbsp;</p></span>
                            <select className='input-inline' name="numRooms" value={residential.numRooms} onChange={handleChange}>
                                {
                                    [...Array(31).keys()].filter(x => x >= 0).map((n, i) => {
                                        return(
                                            <option key={i} value={n}>{n}</option>
                                        )
                                    })
                                }
                            </select>
                            <span><p className="aegis-font-color-2 select-inline">&nbsp;Rooms.</p></span><span className="error-message">{residential.numRooms === '' ? <span> (Required)</span> : null}</span>
                        </div>
                        <div>
                            <span><p className="aegis-font-color-2 input-inline">My property has &nbsp;</p></span>
                            <select className='input-inline' name="floor" value={residential.floor} onChange={handleChange}>
                                {
                                    [...Array(31).keys()].filter(x => x >= 0).map((n, i) => {
                                        return(
                                            <option key={i} value={n}>{n}</option>
                                        )
                                    })
                                }
                            </select>
                            <span><p className="aegis-font-color-2 select-inline">&nbsp;Floor(s).</p></span><span className="error-message">{residential.floor === '' ? <span> (Required)</span> : null}</span>
                        </div>
                        <div>
                            <span><p className="aegis-font-color-2 input-inline">My property has &nbsp;</p></span>
                            <select className='input-inline' name="stairs" value={residential.stairs} onChange={handleChange}>
                                {
                                    [...Array(31).keys()].map((n, i) => {
                                        return(
                                            <option key={i} value={n}>{n}</option>
                                        )
                                    })
                                }
                            </select>
                            <span><p className="aegis-font-color-2 select-inline">&nbsp;Stair(s).</p></span><span className="error-message">{residential.stairs === '' ? <span> (Required)</span> : null}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='grid-section grid-section-color-1 section-margin-bottom'>
                <div className='grid-section-overlay'>
                    <div className='section-form-div'>
                        <form>
                            <h2 className="aegis-font-color-2 input-inline">Tell us more about what you are moving</h2><span className="error-message">{residential.item === '' ? <span> (Required)</span> : null}</span>
                            <textarea name="item" placeholder='1x King Size Bed, 2x wardrobe (need dismantling), 1x dining table, 4x chairs...' value={residential.item} onChange={handleChange}/>

                            <label htmlFor="movingDate" className="aegis-font-color-1">Preferred Moving Date</label>
                            <span className="error-message">{residential.movingDate === '' ? <span> (Required)</span> : null}</span>
                            <span className="error-message">{Date.parse(residential.movingDate) < new Date().setDate(new Date().getDate() + 1) ? <span> (For urgent move, reach us via WhatsApp)</span> : null}</span>
                            <input type="datetime-local" name="movingDate" value={residential.movingDate} onChange={handleChange}/>
                            <br/>
                            <label htmlFor="boxes" className="aegis-font-color-1">Do you need boxes?</label><span className="error-message">{residential.boxes === '' ? <span> (Required)</span> : null}</span>
                            <select name="boxes" value={residential.boxes} onChange={handleChange}>
                                {
                                    [...Array(100).keys()].filter(x => x >= 0).map((n, i) => {
                                        return(
                                            <option key={i} value={n}>{n}</option>
                                        )
                                    })
                                }
                            </select>
                            {
                                residential.boxes > 0 &&
                                <div>
                                    <label htmlFor="boxesDeliver" className="aegis-font-color-1">Boxes Deliver Date</label><span className="error-message">{(Date.parse(residential.boxesDeliver) >= Date.parse(residential.movingDate) || Date.parse(residential.boxesDeliver) < new Date().setDate(new Date().getDate() + 1)) ? <span> (Boxes to be delivered before moving date)</span> : null}</span>

                                    <input type="date" name="boxesDeliver" value={residential.boxesDeliver} onChange={handleChange}/>
                                </div>
                            }

                        </form>
                    </div>
                </div>
            </div>
            <div className='grid-section grid-section-color-1 section-margin-bottom'>
                <div className='grid-section-overlay'>
                    <div className='section-form-div'>
                        <h2 className="aegis-font-color-2 input-inline">Where do you stay?</h2>
                        <br/>
                        <br/>
                        <label htmlFor="address" className="aegis-font-color-1">Where do we pick your items?</label><span className="error-message">{residential.address === '' ? <span> (Required)</span> : null}</span>
                        <input type="text" name="address" value={residential.address} onChange={handleChange}/>
                        <br/>
                        <br/>
                        <label htmlFor="lift" className="aegis-font-color-1">Pick Up location accessible by lift?</label><span className="error-message">{residential.lift === '' ? <span> (Required)</span> : null}</span>
                        <br/>
                        <br/>
                        <label style={{paddingRight:'1rem'}}>
                            <input type="radio" name="lift" value='no' onChange={handleChange} checked={residential.lift==='no'}/>
                            No
                        </label>
                        <label>
                            <input type="radio" name="lift" value='yes' onChange={handleChange} checked={residential.lift==='yes'}/>
                            Yes
                        </label>
                        <br/>
                        <br/>
                        <label htmlFor="info" className="aegis-font-color-1">Additional Remarks?</label><span className="notrequired-message"> (Not Required)</span>
                        <textarea name="info" value={residential.info} onChange={handleChange}/>
                    </div>
                </div>
            </div>
            <div className='grid-section grid-section-color-1 section-margin-bottom'>
                <div className='grid-section-overlay'>
                    <div className='section-form-div'>
                        <h2 className="aegis-font-color-2 input-inline">Where are you moving to?</h2>
                        <br/>
                        <br/>
                        <label htmlFor="dropoffAddress" className="aegis-font-color-1">Where do we drop off your items?</label><span className="error-message">{residential.dropoffAddress === '' ? <span> (Required)</span> : null}</span>
                        <input type="text" name="dropoffAddress" value={residential.dropoffAddress} onChange={handleChange}/>
                        <br/>
                        <br/>
                        <label htmlFor="dropoffLift" className="aegis-font-color-1">Drop Off location accessible by lift?</label><span className="error-message">{residential.dropoffLift === '' ? <span> (Required)</span> : null}</span>
                        <br/>
                        <br/>
                        <label style={{paddingRight:'1rem'}}>
                            <input type="radio" name="dropoffLift" value='no' onChange={handleChange} checked={residential.dropoffLift==='no'}/>
                            No
                        </label>
                        <label>
                            <input type="radio" name="dropoffLift" value='yes' onChange={handleChange} checked={residential.dropoffLift==='yes'}/>
                            Yes
                        </label>
                        <br/>
                        <br/>
                        <label htmlFor="dropoffInfo" className="aegis-font-color-1">Additional Remarks?</label><span className="notrequired-message"> (Not Required)</span>
                        <textarea name="dropoffInfo" value={residential.dropoffInfo} onChange={handleChange}/>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}