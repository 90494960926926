
const Routing = () => {
    let backendApi;
    if (process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT') {
        backendApi = `${process.env.REACT_APP_DEV_SERVER_HEAD}://${process.env.REACT_APP_DEV_SERVER_HOST}:${process.env.REACT_APP_DEV_SERVER_PORT}`
    } else {
        backendApi = `${process.env.REACT_APP_PROD_SERVER_HEAD}://${process.env.REACT_APP_PROD_SERVER_HOST}`
    }

    return backendApi;
}

export default Routing;